<template>
  <section
    ref="purchaseOrderDetail"
    :style="[
      {
        boxSizing: 'border-box',
        width: '100%',
        margin: '0',
        padding: '16px',
        fontFamily: 'Times New Roman',
        fontSize: '13px',
        lineHeight: '1.15',
        color: '#444',
      },
      isSampled
        ? {
            backgroundImage: `url(${require('images/bg_sample.jpg')})`,
            backgroundRepeat: 'repeat',
            backgroundPosition: 'top left',
            backgroundSize: '20%',
          }
        : {},
    ]">
    <!-- style="
      box-sizing: border-box;
      width: 100%;
      margin: 0;
      padding: 16px;
      font-family: 'Times New Roman', 'serif' !important;
      font-size: 13px;
      line-height: 1.15;
      color: #444;
      background-repeat: repeat-y;
      background-position: top center;
      background-size: 841.92pt 595.2pt;
    " -->
    <!-- :style="{
      boxSizing: 'border-box',
      width: '100%',
      margin: '0',
      padding: '16px',
      fontFamily: 'Times New Roman',
      fontSize: '13px',
      lineHeight: '1.15',
      color: '#444',
      backgroundImage: `url(${require('images/bg_sample.jpg')})`,
      backgroundRepeat: 'repeat-y',
      backgroundPosition: 'top center',
      backgroundSize: '841.92pt 595.2pt',
    }" -->

    <!-- 1st row:: s -->
    <div
      style="
        display: flex;
        margin: 0;
        padding-top: 24px;
        padding-bottom: 12px;
        box-sizing: border-box;
        background-color: transparent;
      ">
      <div
        style="flex: 1 1 400px; margin: 0; margin-right: 24px; box-sizing: border-box; font-size: 12px; display: flex">
        <div style="flex: 1 1 150px; overflow: hidden; padding-right: 48px">
          <dl style="margin: 0; margin-bottom: 5px">
            <dt
              style="
                font-size: 15px;
                margin: 0;
                font-weight: 700;
                margin-bottom: 3px;
                color: #000;
                box-sizing: border-box;
              ">
              PO Number
            </dt>
            <dd style="margin: 0">{{ hasValue(po.poNo) ? po.poNo : " " }}</dd>
          </dl>
          <dl style="margin: 0; margin-bottom: 6px">
            <dt
              style="
                font-size: 15px;
                margin: 0;
                font-weight: 700;
                margin-bottom: 3px;
                color: #000;
                box-sizing: border-box;
              ">
              Status
            </dt>
            <dd style="margin: 0">{{ status }}</dd>
          </dl>
        </div>

        <div style="flex: 1 1 180px; overflow: hidden">
          <dl style="margin: 0; margin-bottom: 6px">
            <dt
              style="
                font-size: 15px;
                margin: 0;
                font-weight: 700;
                margin-bottom: 3px;
                color: #000;
                box-sizing: border-box;
              ">
              PO Generated Date & Time
            </dt>
            <dd style="margin: 0; margin-bottom: 3px">
              {{ `${generateTime.format("yyyy-MM-DD HH:mm")}` }}
            </dd>
          </dl>
        </div>
      </div>

      <!-- company info :: s -->
      <div
        style="
          flex: 1 1 600px;
          overflow: hidden;
          margin: 0;
          margin-left: auto;
          display: flex;
          justify-content: flex-end;
          padding-bottom: 16px;
          box-sizing: border-box;
          background-color: transparent;
        ">
        <!-- flex-item s -->
        <div style="flex: 1 1 180px; margin: 0; padding-left: 32px; max-width: 500px">
          <dt style="font-size: 15px; margin: 0; font-weight: 700; margin-bottom: 4px; margin-right: 20px; color: #000">
            PO Issue Company (Bill To)
          </dt>
          <p class="company" style="margin: 0; font-weight: 700; margin-bottom: 4px">{{ po.userCompany.name }}</p>
          <p class="address" style="margin: 0; margin-bottom: 4px">
            {{
              hasValue(po.userCompany.cityCode)
                ? `${po.userCompany.address}, ${po.userCompany.cityCode}`
                : `${po.userCompany.address}`
            }}
          </p>
          <p class="contact" style="margin: 0; margin-bottom: 4px">
            {{ `Tel: ${po.userCompany.contactCallCountry + " " + po.userCompany.contactCall}` }}
            <br />
            {{ `Mail: ${po.userCompany.contactMail || ""}` }}
          </p>
        </div>
        <!-- flex-item e -->

        <!-- flex-item s -->
        <div style="margin: 0; flex: 1 1 180px; padding-left: 32px; max-width: 500px">
          <dt style="margin: 0; font-size: 15px; font-weight: 700; margin-bottom: 4px; margin-right: 20px; color: #000">
            Supplier
          </dt>
          <p class="company" style="margin: 0; font-weight: 700; margin-bottom: 4px">{{ po.supplier.name }}</p>
          <p class="address" style="margin: 0; margin-bottom: 4px">
            {{
              hasValue(po.supplier.cityCode)
                ? `${po.supplier.address}, ${po.supplier.cityCode}`
                : `${po.supplier.address}`
            }}
          </p>
          <p class="contact" style="margin: 0; margin-bottom: 4px">
            {{ `Tel: ${po.supplier.contactCallCountry + " " + po.supplier.contactCall}` }}
          </p>
          <p class="contact" style="margin: 0; margin-bottom: 4px">
            {{ `Mail: ${po.supplier.contactMail || ""}` }}
          </p>
        </div>
        <!-- flex-item e -->
      </div>
      <!-- company info :: e -->
    </div>
    <!-- 1st row:: e -->

    <!-- 2nd row :: s -->
    <div style="display: flex; padding-top: 12px; padding-bottom: 12px; background-color: transparent">
      <!-- country :: s -->
      <div class="cell" style="flex: 1 1 auto; overflow: hidden; font-size: 12px; margin-right: 16px">
        <h4 style="margin: 0; font-size: 13px; font-weight: 700; margin-bottom: 6px; color: #000">COUNTRY</h4>
        <dl style="margin: 0; margin-bottom: 2px; display: flex">
          <dt style="margin: 0; color: #000; font-weight: 600; margin-right: 8px">Origin</dt>
          <dd style="margin: 0" v-html="hasValue(po.cntryOrg) ? po.cntryOrg : noDataFieldHTML"></dd>
        </dl>
        <dl style="margin: 0; margin-bottom: 2px; display: flex">
          <dt style="margin: 0; color: #000; font-weight: 600; margin-right: 8px">Destination</dt>
          <dd style="margin: 0" v-html="hasValue(po.cntryDst) ? po.cntryDst : noDataFieldHTML"></dd>
        </dl>
      </div>
      <!-- country :: e -->

      <!-- port :: s -->
      <div class="cell" style="margin: 0; flex: 1 1 auto; overflow: hidden; font-size: 12px; margin-right: 16px">
        <h4 style="margin: 0; font-size: 13px; font-weight: 700; margin-bottom: 6px; color: #000">PORT</h4>
        <dl style="margin: 0; margin-bottom: 2px; display: flex">
          <dt style="margin: 0; color: #000; font-weight: 600; margin-right: 8px">Loading</dt>
          <dd style="margin: 0" v-html="hasValue(po.portLoad) ? po.portLoad : noDataFieldHTML"></dd>
        </dl>
        <dl style="margin: 0; margin-bottom: 2px; display: flex">
          <dt style="margin: 0; color: #000; font-weight: 600; margin-right: 8px">Discharging</dt>
          <dd style="margin: 0" v-html="hasValue(po.portDis) ? po.portDis : noDataFieldHTML"></dd>
        </dl>
      </div>
      <!-- port :: e -->

      <!-- date :: s -->
      <div class="cell" style="margin: 0; flex: 1 1 auto; overflow: hidden; font-size: 12px; margin-right: 16px">
        <h4 style="margin: 0; font-size: 13px; font-weight: 700; margin-bottom: 6px; color: #000">DATE</h4>
        <dl style="margin: 0; margin-bottom: 2px; display: flex">
          <dt style="margin: 0; color: #000; font-weight: 600; margin-right: 8px">EX-Mill</dt>
          <dd style="margin: 0" v-if="!hasValue(po.exMill)" v-html="noDataFieldHTML"></dd>
          <dd style="margin: 0" v-else>
            {{ $m(po.exMill).format("yyyy-MM-DD") }}
          </dd>
        </dl>
        <dl style="margin: 0; margin-bottom: 2px; display: flex">
          <dt style="margin: 0; color: #000; font-weight: 600; margin-right: 8px">Ship</dt>
          <dd style="margin: 0" v-if="!hasValue(po.shipDate)" v-html="noDataFieldHTML"></dd>
          <dd style="margin: 0" v-else>
            {{ $m(po.shipDate).format("yyyy-MM-DD") }}
          </dd>
        </dl>
        <dl style="margin: 0; margin-bottom: 2px; display: flex">
          <dt style="margin: 0; color: #000; font-weight: 600; margin-right: 8px">In House</dt>
          <dd style="margin: 0" v-if="!hasValue(po.inHouse)" v-html="noDataFieldHTML"></dd>
          <dd style="margin: 0" v-else>
            {{ $m(po.inHouse).format("yyyy-MM-DD") }}
          </dd>
        </dl>
      </div>
      <!-- date :: e -->

      <!-- term :: s -->
      <div class="cell" style="margin: 0; flex: 1 1 auto; overflow: hidden; font-size: 12px; margin-right: 16px">
        <h4 style="margin: 0; font-size: 13px; font-weight: 700; margin-bottom: 6px; color: #000">TERM</h4>
        <dl style="margin: 0; margin-bottom: 2px; display: flex">
          <dt style="margin: 0; color: #000; font-weight: 600; margin-right: 8px">Delivery</dt>
          <dd style="margin: 0" v-html="hasValue(po.termDelivery) ? po.termDelivery : noDataFieldHTML"></dd>
        </dl>
        <dl style="margin: 0; margin-bottom: 2px; display: flex">
          <dt style="margin: 0; color: #000; font-weight: 600; margin-right: 8px">Payment</dt>
          <dd style="margin: 0" v-html="termPaymentText"></dd>
        </dl>
      </div>
      <!-- term :: e -->

      <!-- condition :: s -->
      <div class="cell" style="margin: 0; flex: 1 1 auto; overflow: hidden; font-size: 12px; margin-right: 16px">
        <h4 style="margin: 0; font-size: 13px; font-weight: 700; margin-bottom: 6px; color: #000">CONDITION</h4>
        <dl style="margin: 0; margin-bottom: 2px; display: flex">
          <dt style="margin: 0; color: #000; font-weight: 600; margin-right: 8px">ShipMode</dt>
          <dd style="margin: 0" v-html="hasValue(po.shipMode) ? po.shipMode : noDataFieldHTML"></dd>
        </dl>
        <dl style="margin: 0; margin-bottom: 2px; display: flex">
          <dt style="margin: 0; color: #000; font-weight: 600; margin-right: 8px">Ship Tolerance</dt>
          <dd style="margin: 0" v-html="getToleranceText(this.po.shipToleranceHigher, this.po.shipToleranceLower)"></dd>
        </dl>
        <dl style="margin: 0; margin-bottom: 2px; display: flex">
          <dt style="margin: 0; color: #000; font-weight: 600; margin-right: 8px">Currency</dt>
          <dd style="margin: 0" v-html="hasValue(po.currency) ? po.currency : noDataFieldHTML"></dd>
        </dl>
        <dl style="margin: 0; margin-bottom: 2px; display: flex">
          <dt style="margin: 0; color: #000; font-weight: 600; margin-right: 8px">Partial Shipment</dt>
          <dd style="margin: 0" v-html="hasValue(po.partialShipment) ? po.partialShipment : noDataFieldHTML"></dd>
        </dl>
      </div>
      <!-- condition :: e2 -->
    </div>
    <!-- 2nd row :: e -->

    <!-- area :: s -->
    <div
      v-for="(bom, bomIndex) in po.rmPoBomSelectDtoList"
      :key="`bom ${bomIndex}`"
      class="area"
      style="padding-top: 12px; padding-bottom: 12px; background-color: transparent">
      <!-- areaName :: s -->
      <h2 class="" style="margin: 0; font-size: 15px; font-weight: 700; color: #000; margin-bottom: 8px">
        {{ bom.areaName }}
        <em style="margin-left: 6px; font-style: normal; color: #507baa">
          {{ (bomIndex + 1).toString().padStart(3, 0) }}
        </em>
      </h2>
      <!-- areaName :: e -->

      <!-- mclInfo :: s -->
      <div
        v-if="hasValue(bom.mclUUID) && Array.isArray(bom.mclList) && bom.mclList.length > 0"
        class="top-row"
        style="display: flex; margin-bottom: 4px">
        <dl style="margin: 0; flex: 1 1 100px; overflow: hidden; font-size: 11px; margin-bottom: 2px">
          <dt style="margin: 0; color: #000; font-weight: 600; margin-right: 8px">YEAR / SEASON</dt>
          <dd style="margin: 0">{{ `${bom.bomYear} / ${bom.season}` }}</dd>
        </dl>

        <dl style="margin: 0; flex: 1 1 100px; overflow: hidden; font-size: 11px; margin-bottom: 2px">
          <dt style="margin: 0; color: #000; font-weight: 600; margin-right: 8px">BUYER BRAND</dt>
          <dd style="margin: 0">{{ `${bom.offerBrand}` }}</dd>
        </dl>

        <dl style="margin: 0; flex: 1 1 100px; overflow: hidden; font-size: 11px; margin-bottom: 2px">
          <dt style="margin: 0; color: #000; font-weight: 600; margin-right: 8px">GENDER</dt>
          <dd style="margin: 0">{{ `${bom.gender}` }}</dd>
        </dl>

        <dl style="margin: 0; flex: 1 1 100px; overflow: hidden; font-size: 11px; margin-bottom: 2px">
          <dt style="margin: 0; color: #000; font-weight: 600; margin-right: 8px">GARMENT TYPE</dt>
          <dd style="margin: 0">{{ `${bom.garmentType}` }}</dd>
        </dl>

        <dl style="margin: 0; flex: 1 1 100px; overflow: hidden; font-size: 11px; margin-bottom: 2px">
          <dt style="margin: 0; color: #000; font-weight: 600; margin-right: 8px">FABRIC TYPE</dt>
          <dd style="margin: 0">{{ `${bom.fabricType}` }}</dd>
        </dl>

        <dl style="margin: 0; flex: 1 1 100px; overflow: hidden; font-size: 11px; margin-bottom: 2px">
          <dt style="margin: 0; color: #000; font-weight: 600; margin-right: 8px">DESIGN NUMBER</dt>
          <dd style="margin: 0">{{ `${bom.designNo}` }}</dd>
        </dl>

        <dl style="margin: 0; flex: 1 1 100px; overflow: hidden; font-size: 11px; margin-bottom: 2px">
          <dt style="margin: 0; color: #000; font-weight: 600; margin-right: 8px">STYLE NUMBER</dt>
          <dd style="margin: 0">{{ hasValue(bom.styleNo) ? bom.styleNo.replaceAll("|$|", ", ") : "" }}</dd>
        </dl>

        <dl style="margin: 0; flex: 1 1 100px; overflow: hidden; font-size: 11px; margin-bottom: 2px">
          <dt style="margin: 0; color: #000; font-weight: 600; margin-right: 8px">BOM TITLE</dt>
          <dd style="margin: 0">{{ `${bom.coverName}` }}</dd>
        </dl>
      </div>
      <!-- mclInfo :: e -->

      <!-- table :: s -->
      <table
        style="
          margin: 0;
          font-size: 11px;
          width: 100%;
          border-top: 1px solid #bcbcbc;
          border-spacing: 0;
          text-align: center;
        ">
        <thead style="">
          <tr>
            <th
              style="
                border-left: 1px solid rgb(188, 188, 188);
                border-right: 1px solid #bcbcbc;
                border-bottom: 1px solid #bcbcbc;
                font-weight: 700;
                padding: 2px 4px;
                width: 12%;
                box-sizing: border-box;
              ">
              Mill/ITEM #
            </th>
            <th
              style="
                border-right: 1px solid #bcbcbc;
                border-bottom: 1px solid #bcbcbc;
                font-weight: 700;
                padding: 2px 4px;
                width: 6%;
                box-sizing: border-box;
              ">
              RD #
            </th>
            <th
              style="
                border-right: 1px solid #bcbcbc;
                border-bottom: 1px solid #bcbcbc;
                font-weight: 700;
                padding: 2px 4px;
                width: 10%;
                box-sizing: border-box;
              ">
              Item Name
            </th>
            <th
              style="
                border-right: 1px solid #bcbcbc;
                border-bottom: 1px solid #bcbcbc;
                font-weight: 700;
                padding: 2px 4px;
                width: 12%;
                box-sizing: border-box;
              ">
              COMPOSITION<br />ITEM DETAIL
            </th>
            <th
              style="
                border-right: 1px solid #bcbcbc;
                border-bottom: 1px solid #bcbcbc;
                font-weight: 700;
                padding: 2px 4px;
                width: 8%;
                box-sizing: border-box;
              ">
              CW/SIZE
            </th>
            <th
              style="
                border-right: 1px solid #bcbcbc;
                border-bottom: 1px solid #bcbcbc;
                font-weight: 700;
                padding: 2px 4px;
                width: 12%;
                box-sizing: border-box;
              ">
              Color
            </th>
            <th
              style="
                border-right: 1px solid #bcbcbc;
                border-bottom: 1px solid #bcbcbc;
                font-weight: 700;
                padding: 2px 4px;
                width: 6%;
                box-sizing: border-box;
              ">
              Unit Price
            </th>
            <th
              style="
                border-right: 1px solid #bcbcbc;
                border-bottom: 1px solid #bcbcbc;
                font-weight: 700;
                padding: 2px 4px;
                width: 10%;
                box-sizing: border-box;
              ">
              PO Qty
            </th>
            <th
              style="
                border-right: 1px solid #bcbcbc;
                border-bottom: 1px solid #bcbcbc;
                font-weight: 700;
                padding: 2px 4px;
                width: 8%;
                box-sizing: border-box;
              ">
              UOM<br />(Supplier)
            </th>
            <th
              style="
                border-right: 1px solid #bcbcbc;
                border-bottom: 1px solid #bcbcbc;
                font-weight: 700;
                padding: 2px 4px;
                width: 10%;
                box-sizing: border-box;
              ">
              Total Value
            </th>
            <th
              style="
                border-right: 1px solid #bcbcbc;
                border-bottom: 1px solid #bcbcbc;
                font-weight: 700;
                padding: 2px 4px;
                width: 6%;
                box-sizing: border-box;
              ">
              Remark
            </th>
          </tr>
        </thead>
        <tbody>
          <template v-for="(mcl, mclIndex) in bom.mclList">
            <template v-for="(mclItem, mclItemIndex) in mcl.items">
              <!-- mcl row :: s -->
              <tr
                :key="`tr mclItem ${bom.bomUUID} ${mcl.mtrlUUID} ${mclItem.rmpoMclId} ${bomIndex} ${mclIndex} ${mclItemIndex} `">
                <td
                  style="
                    border-left: 1px solid rgb(188, 188, 188);
                    border-right: 1px solid #bcbcbc;
                    border-bottom: 1px solid #bcbcbc;
                    color: #444;
                    padding: 2px 6px;
                  ">
                  {{ mclItem.itemNo }}
                </td>
                <td
                  style="
                    border-right: 1px solid #bcbcbc;
                    border-bottom: 1px solid #bcbcbc;
                    color: #444;
                    padding: 2px 6px;
                  ">
                  {{ mclItem.rdNo }}
                </td>
                <td
                  style="
                    border-right: 1px solid #bcbcbc;
                    border-bottom: 1px solid #bcbcbc;
                    color: #444;
                    padding: 2px 6px;
                  ">
                  {{ mclItem.itemName }}
                </td>
                <td
                  style="
                    border-right: 1px solid #bcbcbc;
                    border-bottom: 1px solid #bcbcbc;
                    color: #444;
                    padding: 2px 6px;
                  "
                  v-html="mclItem.mtrlCompRate"></td>
                <td
                  style="
                    border-right: 1px solid #bcbcbc;
                    border-bottom: 1px solid #bcbcbc;
                    color: #444;
                    padding: 2px 6px;
                  ">
                  {{ numberWithComma(mclItem.cw) }}
                </td>
                <td
                  style="
                    border-right: 1px solid #bcbcbc;
                    border-bottom: 1px solid #bcbcbc;
                    color: #444;
                    padding: 2px 6px;
                  ">
                  {{ mclItem.color }}
                </td>
                <td
                  style="
                    border-right: 1px solid #bcbcbc;
                    border-bottom: 1px solid #bcbcbc;
                    color: #444;
                    padding: 2px 6px;
                  ">
                  {{ numberWithComma(mclItem.unitPrice) }}
                </td>
                <td
                  style="
                    border-right: 1px solid #bcbcbc;
                    border-bottom: 1px solid #bcbcbc;
                    color: #444;
                    padding: 2px 6px;
                  ">
                  {{ numberWithComma(mclItem.poQty) }}
                </td>
                <td
                  style="
                    border-right: 1px solid #bcbcbc;
                    border-bottom: 1px solid #bcbcbc;
                    color: #444;
                    padding: 2px 6px;
                  ">
                  {{ mclItem.fwCwUom }}
                </td>
                <td
                  style="
                    border-right: 1px solid #bcbcbc;
                    border-bottom: 1px solid #bcbcbc;
                    color: #444;
                    padding: 2px 6px;
                  ">
                  {{ numberWithComma(fixedNumber(mclItem.amount, $mathFix)) }}
                </td>
                <td
                  style="
                    border-right: 1px solid #bcbcbc;
                    border-bottom: 1px solid #bcbcbc;
                    color: #444;
                    padding: 2px 6px;
                  ">
                  {{ mclItem.remark }}
                </td>
              </tr>
              <!-- mcl row :: e -->
              <!-- expand row quantity :: s -->
              <tr
                :class="{ 'expand-tr': showDetail && hasValue(bom.mclUUID) }"
                v-show="showDetail && hasValue(bom.mclUUID)"
                :key="`tr mclItem expand-row ${bom.bomUUID} ${mcl.mtrlUUID} ${mclItem.rmpoMclId} ${bomIndex} ${mclIndex} ${mclItemIndex} `">
                <td colspan="100" class="expand-row">
                  <div class="quantity-wrap" style="padding: 10px 15px">
                    <h4
                      style="
                        margin: 0;
                        font-size: 13px;
                        font-weight: 700;
                        margin-bottom: 6px;
                        color: #000;
                        text-align: left;
                      ">
                      QUANTITY DETAIL DATA
                    </h4>
                    <!-- useDetailData is False :: s -->
                    <table
                      v-if="mclItem.isQtyDtl === 'N'"
                      style="
                        margin: 0;
                        font-size: 11px;
                        width: 100%;
                        border-left: 1px solid #bcbcbc;
                        border-top: 1px solid #bcbcbc;
                        border-spacing: 0;
                        text-align: center;
                      ">
                      <thead>
                        <tr>
                          <!-- <th
                            style="
                              border-right: 1px solid #bcbcbc;
                              border-bottom: 1px solid #bcbcbc;
                              font-weight: 700;
                              padding: 2px 4px;
                              width: 8%;
                              box-sizing: border-box;
                            ">
                            NET YY
                          </th> -->
                          <!-- <th
                            style="
                              border-right: 1px solid #bcbcbc;
                              border-bottom: 1px solid #bcbcbc;
                              font-weight: 700;
                              padding: 2px 4px;
                              width: 10%;
                              box-sizing: border-box;
                            ">
                            LOSS %
                          </th> -->
                          <!-- <th
                            style="
                              border-right: 1px solid #bcbcbc;
                              border-bottom: 1px solid #bcbcbc;
                              font-weight: 700;
                              padding: 2px 4px;
                              width: 10%;
                              box-sizing: border-box;
                            ">
                            GROSS YY
                          </th> -->
                          <!-- <th
                            style="
                              border-right: 1px solid #bcbcbc;
                              border-bottom: 1px solid #bcbcbc;
                              font-weight: 700;
                              padding: 2px 4px;
                              width: 10%;
                              box-sizing: border-box;
                            ">
                            REQUIRED QTY
                          </th> -->
                          <!-- <th
                            style="
                              border-right: 1px solid #bcbcbc;
                              border-bottom: 1px solid #bcbcbc;
                              font-weight: 700;
                              padding: 2px 4px;
                              width: 10%;
                              box-sizing: border-box;
                            ">
                            STOCK QTY
                          </th> -->
                          <!-- <th
                            style="
                              border-right: 1px solid #bcbcbc;
                              border-bottom: 1px solid #bcbcbc;
                              font-weight: 700;
                              padding: 2px 4px;
                              width: 8%;
                              box-sizing: border-box;
                            ">
                            ISSUED QTY
                          </th> -->
                          <!-- <th
                            style="
                              border-right: 1px solid #bcbcbc;
                              border-bottom: 1px solid #bcbcbc;
                              font-weight: 700;
                              padding: 2px 4px;
                              width: 12%;
                              box-sizing: border-box;
                            ">
                            BALANCE QTY
                          </th> -->
                          <th
                            style="
                              border-right: 1px solid #bcbcbc;
                              border-bottom: 1px solid #bcbcbc;
                              font-weight: 700;
                              padding: 2px 4px;
                              width: 8%;
                              box-sizing: border-box;
                            ">
                            UNIT PRICE
                          </th>
                          <th
                            style="
                              border-right: 1px solid #bcbcbc;
                              border-bottom: 1px solid #bcbcbc;
                              font-weight: 700;
                              padding: 2px 4px;
                              width: 12%;
                              box-sizing: border-box;
                            ">
                            PO QTY
                          </th>
                          <th
                            style="
                              border-right: 1px solid #bcbcbc;
                              border-bottom: 1px solid #bcbcbc;
                              font-weight: 700;
                              padding: 2px 4px;
                              width: 12%;
                              box-sizing: border-box;
                            ">
                            TOTAL VALUE
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <!-- <td
                            style="
                              border-right: 1px solid #bcbcbc;
                              border-bottom: 1px solid #bcbcbc;
                              color: #444;
                              padding: 2px 6px;
                            ">
                            {{
                              numberWithComma(
                                mclItem.rmPoMclQtyDtlSelectDtoList[0] ? mclItem.rmPoMclQtyDtlSelectDtoList[0].netYy : 0,
                              )
                            }}
                          </td> -->
                          <!-- <td
                            style="
                              border-right: 1px solid #bcbcbc;
                              border-bottom: 1px solid #bcbcbc;
                              color: #444;
                              padding: 2px 6px;
                            ">
                            {{
                              numberWithComma(
                                mclItem.rmPoMclQtyDtlSelectDtoList[0] ? mclItem.rmPoMclQtyDtlSelectDtoList[0].loss : 0,
                              )
                            }}
                          </td> -->
                          <!-- <td
                            style="
                              border-right: 1px solid #bcbcbc;
                              border-bottom: 1px solid #bcbcbc;
                              color: #444;
                              padding: 2px 6px;
                            ">
                            {{
                              numberWithComma(
                                mclItem.rmPoMclQtyDtlSelectDtoList[0]
                                  ? fixedNumber(mclItem.rmPoMclQtyDtlSelectDtoList[0].grossYy, $mathFix, true)
                                  : 0,
                              )
                            }}
                          </td> -->
                          <!-- <td
                            style="
                              border-right: 1px solid #bcbcbc;
                              border-bottom: 1px solid #bcbcbc;
                              color: #444;
                              padding: 2px 6px;
                            ">
                            {{
                              numberWithComma(
                                fixedNumber(
                                  mclItem.rmPoMclQtyDtlSelectDtoList
                                    .map(i => i.reqQty)
                                    .reduce((partialSum, a) => partialSum + a, 0),
                                  $mathFix,
                                  true,
                                ),
                              )
                            }}
                          </td>-->
                          <!-- <td
                            style="
                              border-right: 1px solid #bcbcbc;
                              border-bottom: 1px solid #bcbcbc;
                              color: #444;
                              padding: 2px 6px;
                            ">
                            {{
                              numberWithComma(
                                mclItem.rmPoMclQtyDtlSelectDtoList
                                  .map(i => i.stockQty)
                                  .reduce((partialSum, a) => partialSum + a, 0),
                              )
                            }}
                          </td>-->
                          <!-- <td
                            style="
                              border-right: 1px solid #bcbcbc;
                              border-bottom: 1px solid #bcbcbc;
                              color: #444;
                              padding: 2px 6px;
                            ">
                            {{
                              numberWithComma(
                                fixedNumber(
                                  mclItem.rmPoMclQtyDtlSelectDtoList
                                    .map(i => i.issuedQty)
                                    .reduce((partialSum, a) => partialSum + a, 0),
                                  $mathFix,
                                  true,
                                ),
                              )
                            }}
                          </td>-->
                         <!--  <td
                            style="
                              border-right: 1px solid #bcbcbc;
                              border-bottom: 1px solid #bcbcbc;
                              color: #444;
                              padding: 2px 6px;
                            ">
                            {{
                              numberWithComma(
                                fixedNumber(
                                  mclItem.rmPoMclQtyDtlSelectDtoList
                                    .map(i => i.balanceQty)
                                    .reduce((partialSum, a) => partialSum + a, 0),
                                  $mathFix,
                                  true,
                                ),
                              )
                            }}
                          </td>-->
                          <td
                            style="
                              border-right: 1px solid #bcbcbc;
                              border-bottom: 1px solid #bcbcbc;
                              color: #444;
                              padding: 2px 6px;
                            ">
                            {{ numberWithComma(mclItem.unitPrice) }}
                          </td>
                          <td
                            style="
                              border-right: 1px solid #bcbcbc;
                              border-bottom: 1px solid #bcbcbc;
                              color: #444;
                              padding: 2px 6px;
                            ">
                            {{ numberWithComma(mclItem.poQty) }}
                          </td>
                          <td
                            style="
                              border-right: 1px solid #bcbcbc;
                              border-bottom: 1px solid #bcbcbc;
                              color: #444;
                              padding: 2px 6px;
                            ">
                            {{ numberWithComma(fixedNumber(mclItem.amount, $mathFix, true)) }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <!-- useDetailData is False :: e -->
                    <!-- useDetailData is True :: s -->
                    <table
                      v-else
                      style="
                        margin: 0;
                        font-size: 11px;
                        width: 100%;
                        border-left: 1px solid #bcbcbc;
                        border-top: 1px solid #bcbcbc;
                        border-spacing: 0;
                        text-align: center;
                      ">
                      <thead>
                        <tr>
                          <th
                            style="
                              width: 1%;
                              border-right: 1px solid #bcbcbc;
                              border-bottom: 1px solid #bcbcbc;
                              font-weight: 700;
                              padding: 2px 4px;
                              box-sizing: border-box;
                            ">
                            NO.
                          </th>
                          <th
                            style="
                              width: 5%;
                              border-right: 1px solid #bcbcbc;
                              border-bottom: 1px solid #bcbcbc;
                              font-weight: 700;
                              padding: 2px 4px;
                              box-sizing: border-box;
                            ">
                            MARKET
                          </th>
                          <th
                            style="
                              width: 5%;
                              border-right: 1px solid #bcbcbc;
                              border-bottom: 1px solid #bcbcbc;
                              font-weight: 700;
                              padding: 2px 4px;
                              box-sizing: border-box;
                            ">
                            GARMENT SIZE
                          </th>
                          <th
                            style="
                              width: 5%;
                              border-right: 1px solid #bcbcbc;
                              border-bottom: 1px solid #bcbcbc;
                              font-weight: 700;
                              padding: 2px 4px;
                              box-sizing: border-box;
                            ">
                            GARMENT COLOR
                          </th>
                          <!--<th
                            style="
                              width: 7%;
                              border-right: 1px solid #bcbcbc;
                              border-bottom: 1px solid #bcbcbc;
                              font-weight: 700;
                              padding: 2px 4px;
                              box-sizing: border-box;
                            ">
                            NET YY
                          </th>-->
                          <!--<th
                            style="
                              width: 7%;
                              border-right: 1px solid #bcbcbc;
                              border-bottom: 1px solid #bcbcbc;
                              font-weight: 700;
                              padding: 2px 4px;
                              box-sizing: border-box;
                            ">
                            LOSS %
                          </th>-->
                          <!-- <th
                            style="
                              width: 7%;
                              border-right: 1px solid #bcbcbc;
                              border-bottom: 1px solid #bcbcbc;
                              font-weight: 700;
                              padding: 2px 4px;
                              box-sizing: border-box;
                            ">
                            GROSS YY
                          </th> -->
                          <!-- <th
                            style="
                              width: 7%;
                              border-right: 1px solid #bcbcbc;
                              border-bottom: 1px solid #bcbcbc;
                              font-weight: 700;
                              padding: 2px 4px;
                              box-sizing: border-box;
                            ">
                            REQUIRED QTY
                          </th> -->
                          <!-- <th
                            style="
                              width: 7%;
                              border-right: 1px solid #bcbcbc;
                              border-bottom: 1px solid #bcbcbc;
                              font-weight: 700;
                              padding: 2px 4px;
                              box-sizing: border-box;
                            ">
                            STOCK QTY
                          </th> -->
                          <!-- <th
                            style="
                              width: 7%;
                              border-right: 1px solid #bcbcbc;
                              border-bottom: 1px solid #bcbcbc;
                              font-weight: 700;
                              padding: 2px 4px;
                              box-sizing: border-box;
                            ">
                            ISSUED QTY
                          </th> -->
                          <!-- <th
                            style="
                              width: 7%;
                              border-right: 1px solid #bcbcbc;
                              border-bottom: 1px solid #bcbcbc;
                              font-weight: 700;
                              padding: 2px 4px;
                              box-sizing: border-box;
                            ">
                            BALANCE QTY
                          </th> -->
                          <th
                            style="
                              width: 7%;
                              border-right: 1px solid #bcbcbc;
                              border-bottom: 1px solid #bcbcbc;
                              font-weight: 700;
                              padding: 2px 4px;
                              box-sizing: border-box;
                            ">
                            UNIT PRICE
                          </th>
                          <th
                            style="
                              width: 7%;
                              border-right: 1px solid #bcbcbc;
                              border-bottom: 1px solid #bcbcbc;
                              font-weight: 700;
                              padding: 2px 4px;
                              box-sizing: border-box;
                            ">
                            PO QTY
                          </th>
                          <th
                            style="
                              width: 9%;
                              border-right: 1px solid #bcbcbc;
                              border-bottom: 1px solid #bcbcbc;
                              font-weight: 700;
                              padding: 2px 4px;
                              box-sizing: border-box;
                            ">
                            TOTAL VALUE
                          </th>
                          <th
                            style="
                              width: 9%;
                              border-right: 1px solid #bcbcbc;
                              border-bottom: 1px solid #bcbcbc;
                              font-weight: 700;
                              padding: 2px 4px;
                              box-sizing: border-box;
                            ">
                            REMARK
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(qty, qtyIndex) in mclItem.rmPoMclQtyDtlSelectDtoList"
                          :key="`tr mclItem expand-row detail ${bom.bomUUID} ${mcl.mtrlUUID} ${mclItem.rmpoMclId} ${qty.rmpoUUID} ${bomIndex} ${mclIndex} ${mclItemIndex} ${qtyIndex}`">
                          <td
                            style="
                              border-right: 1px solid #bcbcbc;
                              border-bottom: 1px solid #bcbcbc;
                              color: #444;
                              padding: 2px 6px;
                            ">
                            {{ qtyIndex + 1 }}
                          </td>
                          <td
                            style="
                              border-right: 1px solid #bcbcbc;
                              border-bottom: 1px solid #bcbcbc;
                              color: #444;
                              padding: 2px 6px;
                            ">
                            {{ qty.market === "All" ? "" : qty.market }}
                          </td>
                          <td
                            style="
                              border-right: 1px solid #bcbcbc;
                              border-bottom: 1px solid #bcbcbc;
                              color: #444;
                              padding: 2px 6px;
                            ">
                            {{ qty.size === "All" ? "" : qty.size }}
                          </td>
                          <td
                            style="
                              border-right: 1px solid #bcbcbc;
                              border-bottom: 1px solid #bcbcbc;
                              color: #444;
                              padding: 2px 6px;
                            ">
                            {{ qty.color === "All" ? "" : qty.color }}
                          </td>
                          <!-- <td
                            style="
                              border-right: 1px solid #bcbcbc;
                              border-bottom: 1px solid #bcbcbc;
                              color: #444;
                              padding: 2px 6px;
                            ">
                            {{ numberWithComma(qty.netYy) }}
                          </td> -->
                          <!-- <td
                            style="
                              border-right: 1px solid #bcbcbc;
                              border-bottom: 1px solid #bcbcbc;
                              color: #444;
                              padding: 2px 6px;
                            ">
                            {{ numberWithComma(qty.loss) }}
                          </td> -->
                          <!-- <td
                            style="
                              border-right: 1px solid #bcbcbc;
                              border-bottom: 1px solid #bcbcbc;
                              color: #444;
                              padding: 2px 6px;
                            ">
                            {{ numberWithComma(fixedNumber(qty.grossYy, $mathFix, true)) }}
                          </td> -->
                          <!-- <td
                            style="
                              border-right: 1px solid #bcbcbc;
                              border-bottom: 1px solid #bcbcbc;
                              color: #444;
                              padding: 2px 6px;
                            ">
                            {{ numberWithComma(fixedNumber(qty.reqQty, $mathFix, true)) }}
                          </td> -->
                          <!-- <td
                            style="
                              border-right: 1px solid #bcbcbc;
                              border-bottom: 1px solid #bcbcbc;
                              color: #444;
                              padding: 2px 6px;
                            ">
                            {{ numberWithComma(qty.stockQty) }}
                          </td> -->
                          <!-- <td
                            style="
                              border-right: 1px solid #bcbcbc;
                              border-bottom: 1px solid #bcbcbc;
                              color: #444;
                              padding: 2px 6px;
                            ">
                            {{ numberWithComma(fixedNumber(qty.issuedQty, $mathFix, true)) }}
                          </td> -->
                          <!-- <td
                            style="
                              border-right: 1px solid #bcbcbc;
                              border-bottom: 1px solid #bcbcbc;
                              color: #444;
                              padding: 2px 6px;
                            ">
                            {{ numberWithComma(fixedNumber(qty.balanceQty, $mathFix, true)) }}
                          </td> -->
                          <td
                            style="
                              border-right: 1px solid #bcbcbc;
                              border-bottom: 1px solid #bcbcbc;
                              color: #444;
                              padding: 2px 6px;
                            ">
                            {{ numberWithComma(qty.unitPrice) }}
                          </td>
                          <td
                            style="
                              border-right: 1px solid #bcbcbc;
                              border-bottom: 1px solid #bcbcbc;
                              color: #444;
                              padding: 2px 6px;
                            ">
                            {{ numberWithComma(qty.poQty) }}
                          </td>
                          <td
                            style="
                              border-right: 1px solid #bcbcbc;
                              border-bottom: 1px solid #bcbcbc;
                              color: #444;
                              padding: 2px 6px;
                            ">
                            {{ numberWithComma(fixedNumber(qty.amount, $mathFix, true)) }}
                          </td>
                          <td
                            style="
                              border-right: 1px solid #bcbcbc;
                              border-bottom: 1px solid #bcbcbc;
                              color: #444;
                              padding: 2px 6px;
                            ">
                            {{ qty.remark }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <!-- useDetailData is True :: e -->
                  </div>
                </td>
              </tr>
              <!-- expand row quantity :: e -->
            </template>
            <!-- subTotal :: s -->
            <tr
              class="sub-total"
              v-if="mcl.items.length > 1"
              :key="`tbody mcl ${bom.bomUUID} ${mcl.mtrlUUID} ${bomIndex} ${mclIndex}`">
              <td
                colspan="6"
                style="
                  border-left: 1px solid #bcbcbc;
                  border-right: 1px solid #bcbcbc;
                  border-bottom: 1px solid #bcbcbc;
                  color: #444;
                  padding: 2px 6px;
                ">
                Sub Total of Items
              </td>
              <td
                style="
                  border-right: 1px solid #bcbcbc;
                  border-bottom: 1px solid #bcbcbc;
                  color: #444;
                  padding: 2px 6px;
                "></td>
              <td
                style="
                  border-right: 1px solid #bcbcbc;
                  border-bottom: 1px solid #bcbcbc;
                  color: #444;
                  padding: 2px 6px;
                ">
                {{
                  numberWithComma(
                    fixedNumber(
                      mcl.items.map(i => i.poQty).reduce((partialSum, a) => partialSum + (a || 0), 0),
                      $mathFix,
                      true,
                    ),
                  )
                }}
              </td>
              <td
                style="
                  border-right: 1px solid #bcbcbc;
                  border-bottom: 1px solid #bcbcbc;
                  color: #444;
                  padding: 2px 6px;
                "></td>
              <td
                style="
                  border-right: 1px solid #bcbcbc;
                  border-bottom: 1px solid #bcbcbc;
                  color: #444;
                  padding: 2px 6px;
                "></td>
              <td
                style="
                  border-right: 1px solid #bcbcbc;
                  border-bottom: 1px solid #bcbcbc;
                  color: #444;
                  padding: 2px 6px;
                "></td>
            </tr>
            <!-- subTotal :: e -->
          </template>
          <tr class="grand-total">
            <td
              colspan="6"
              style="
                border-left: 1px solid #bcbcbc;
                border-right: 1px solid #bcbcbc;
                border-bottom: 1px solid #bcbcbc;
                color: #444;
                padding: 2px 6px;
              ">
              Grand Total of Items
            </td>
            <td
              style="border-right: 1px solid #bcbcbc; border-bottom: 1px solid #bcbcbc; color: #444; padding: 2px 6px">
              -
            </td>
            <td
              style="border-right: 1px solid #bcbcbc; border-bottom: 1px solid #bcbcbc; color: #444; padding: 2px 6px">
              {{ numberWithComma(getGrandTotalPoQty(bom.mclList, $mathFix)) }}
            </td>
            <td
              style="
                border-right: 1px solid #bcbcbc;
                border-bottom: 1px solid #bcbcbc;
                color: #444;
                padding: 2px 6px;
              "></td>
            <td
              style="border-right: 1px solid #bcbcbc; border-bottom: 1px solid #bcbcbc; color: #444; padding: 2px 6px">
              {{ numberWithComma(getGrandTotalValue(bom.mclList, $mathFix)) }}
            </td>
            <td
              style="
                border-right: 1px solid #bcbcbc;
                border-bottom: 1px solid #bcbcbc;
                color: #444;
                padding: 2px 6px;
              "></td>
          </tr>
        </tbody>
        <!-- <tbody>
          <tr class="grand-total">
            <td
              colspan="6"
              style="
                border-left: 1px solid #bcbcbc;
                border-right: 1px solid #bcbcbc;
                border-bottom: 1px solid #bcbcbc;
                color: #444;
                padding: 2px 6px;
              ">
              Grand Total of Items
            </td>
            <td
              style="border-right: 1px solid #bcbcbc; border-bottom: 1px solid #bcbcbc; color: #444; padding: 2px 6px">
              -
            </td>
            <td
              style="border-right: 1px solid #bcbcbc; border-bottom: 1px solid #bcbcbc; color: #444; padding: 2px 6px">
              {{ numberWithComma(getGrandTotalPoQty(bom.mclList, $mathFix)) }}
            </td>
            <td
              style="
                border-right: 1px solid #bcbcbc;
                border-bottom: 1px solid #bcbcbc;
                color: #444;
                padding: 2px 6px;
              "></td>
            <td
              style="border-right: 1px solid #bcbcbc; border-bottom: 1px solid #bcbcbc; color: #444; padding: 2px 6px">
              {{ numberWithComma(getGrandTotalValue(bom.mclList, $mathFix)) }}
            </td>
            <td
              style="
                border-right: 1px solid #bcbcbc;
                border-bottom: 1px solid #bcbcbc;
                color: #444;
                padding: 2px 6px;
              "></td>
          </tr>
        </tbody> -->
      </table>
      <!-- table :: e -->
    </div>
    <!-- area :: e -->

    <!-- total s -->
    <div class="total" style="display: flex; flex-direction: column; align-items: flex-end; margin-top: 8px">
      <dl style="margin: 0; display: flex; overflow: hidden; margin-bottom: 6px">
        <dt style="margin: 0; font-weight: 700; color: #000; flex: none; width: 260px; text-align: left">Order Qty</dt>
        <dd style="margin: 0; lex: none; width: 200px; text-align: right">{{ orderQtyText }}</dd>
      </dl>
      <dl style="margin: 0; display: flex; overflow: hidden; margin-bottom: 6px">
        <dt style="margin: 0; font-weight: 700; color: #000; flex: none; width: 260px; text-align: left">
          Amount{{ hasValue(po.currency) ? ` (${po.currency})` : "" }}
        </dt>
        <dd style="margin: 0; flex: none; width: 200px; text-align: right">
          {{ numberWithComma(fixedNumber(amountText, $mathFix, true)) }}
        </dd>
      </dl>
      <dl style="margin: 0; display: flex; overflow: hidden; margin-bottom: 6px">
        <dt style="margin: 0; font-weight: 700; color: #000; flex: none; width: 260px; text-align: left">
          Upcharge or Discount (%)
        </dt>
        <dd style="margin: 0; flex: none; width: 200px; text-align: right">{{ changeAmountText }}</dd>
      </dl>
      <dl style="margin: 0; display: flex; overflow: hidden; margin-bottom: 6px">
        <dt style="margin: 0; font-weight: 700; color: #000; flex: none; width: 260px; text-align: left">
          Purchase Order Value{{ hasValue(po.currency) ? ` (${po.currency})` : "" }}
        </dt>
        <dd style="margin: 0; flex: none; width: 200px; text-align: right">
          {{ numberWithComma(fixedNumber(finalAmountText, $mathFix, true)) }}
        </dd>
      </dl>
    </div>
    <!-- total e -->

    <!-- signature s -->
    <div
      style="
        margin: 0;
        display: flex;
        justify-content: space-between;
        padding-top: 24px;
        padding-bottom: 12px;
        font-size: 11px;
      ">
      <div class="cell" style="flex: none; max-width: 40%">
        <h4 style="margin: 0; font-size: 13px; font-weight: 700; color: #000; margin-bottom: 6px">Consignee</h4>
        <template v-if="hasValue(po.consigneeCompany)">
          <p style="margin: 0; color: #000; font-weight: 700; margin-bottom: 6px" v-if="po.consigneeCompany.name === 'PT. DAEHAN GLOBAL SUKABUMI'">PT. DAEHAN GLOBAL</p>
          <p style="margin: 0; color: #000; font-weight: 700; margin-bottom: 6px" v-else-if="po.consigneeCompany.name === 'PT. DAEHAN GLOBAL BREBES(INTERIM ONLY)'">PT. DAEHAN GLOBAL</p>
          <p style="margin: 0; color: #000; font-weight: 700; margin-bottom: 6px" v-else>{{po.consigneeCompany.name}}</p>
          <p style="margin: 0; margin-bottom: 3px">{{ po.consigneeCompany.address }}</p>
          <p style="margin: 0; margin-bottom: 3px">
            {{
              hasValue(po.consigneeCompany.cityCode)
                ? `${po.consigneeCompany.cityCode}, ${po.consigneeCompany.countryCode}`
                : `${po.consigneeCompany.countryCode}`
            }}
          </p>
          <p style="margin: 0; margin-bottom: 3px">
            {{
              `Tel : ${po.consigneeCompany.contactCallCountry + " " + po.consigneeCompany.contactCall} / Mail : ${
                po.consigneeCompany.contactMail
              }`
            }}
          </p>
        </template>
        <span v-else v-html="noDataFieldHTML"></span>
      </div>

      <div class="cell" style="flex: none; max-width: 40%">
        <h4 style="margin: 0; font-size: 13px; font-weight: 700; color: #000; margin-bottom: 6px">Ship To</h4>
        <template v-if="hasValue(po.shipToCompany)">
          <p style="margin: 0; color: #000; font-weight: 700; margin-bottom: 6px" class="sub-title" v-if="po.shipToCompany.name === 'PT. DAEHAN GLOBAL SUKABUMI'">PT. DAEHAN GLOBAL</p>
          <p style="margin: 0; color: #000; font-weight: 700; margin-bottom: 6px" class="sub-title" v-else-if="po.shipToCompany.name === 'PT. DAEHAN GLOBAL BREBES(INTERIM ONLY)'">PT. DAEHAN GLOBAL</p>
          <p style="margin: 0; color: #000; font-weight: 700; margin-bottom: 6px" class="sub-title" v-else>{{po.shipToCompany.name}}</p>
          <p style="margin: 0; margin-bottom: 3px">{{ po.shipToCompany.address }}</p>
          <p style="margin: 0; margin-bottom: 3px">
            {{
              hasValue(po.shipToCompany.cityCode)
                ? `${po.shipToCompany.cityCode}, ${po.shipToCompany.countryCode}`
                : `${po.shipToCompany.countryCode}`
            }}
          </p>
          <p style="margin: 0; margin-bottom: 3px">
            {{
              `Tel : ${po.shipToCompany.contactCallCountry + " " + po.shipToCompany.contactCall} / Mail : ${
                po.shipToCompany.contactMail
              }`
            }}
          </p>
        </template>
        <span v-else v-html="noDataFieldHTML"></span>
      </div>
    </div>

    <div
      style="
        margin: 0;
        display: flex;
        justify-content: space-between;
        padding-top: 24px;
        padding-bottom: 12px;
        font-size: 11px;
      ">
      <div class="cell" style="flex: none">
        <h4 style="margin: 0; font-size: 13px; font-weight: 700; color: #000; margin-bottom: 6px">Prepared By</h4>
        <p style="margin: 0; color: #000; font-weight: 700; margin-bottom: 6px">
          {{ `${po.rmPoRegUser.name} (${po.rmPoRegUser.email}) / ${po.rmPoRegUser.mobilePhone}` }}
        </p>
        <p style="margin: 0; margin-bottom: 3px">
          {{
            `Tel : ${po.userCompany.contactCallCountry + " " + po.userCompany.contactCall} / 
                  Mail : ${po.userCompany.contactMail}`
          }}
        </p>
      </div>

      <div class="cell" style="flex: none; align-self: flex-end">
        <h4 style="margin: 0; font-size: 13px; font-weight: 700; color: #000; margin-bottom: 6px">
          Authorized Signature
        </h4>
      </div>
    </div>
    <!-- signature e -->

    <div style="margin: 0; padding-top: 12px">
      <h4>
        PO NUMBER IS MANDATORY IN THE PI, CL AND PACKING LIST. IF NOT MENTIONED PAYMENT WILL BE DELAYED UNTILL IT IS
        FIXED
      </h4>
      <br />
      REMARKS : -
      <div>
        <span style="margin-right: 8px">1.</span> All bulk shipment must be met Buyer standard quality requirement and
        need to be approved by concerned Buyer office <br />
        <span style="margin-right: 8px">2.</span> Material should be tested and approved under CPSC approved lab.
        Singnature by : <br />
        <span style="margin-right: 8px">3.</span> if shipment delay without our confirmation, supplier should ship by
        air pre-paid. <br />
        <span style="margin-right: 8px">4.</span> Bulk Fabric shrinkage +/- 3%. Hence pls ensure whatever s/y & bulk
        which youâre sending to us should be within 5% tolerance range. <br />
        <span style="margin-right: 8px">5.</span> Quantity tolerance level - 0% (mills need ship 100% full quantity as
        per Po booking) and +1% allowed for bulk shipment. <br />
        <span style="margin-right: 8px">6.</span> Mill should follow the Fabric SOP advised by Buyer and get all the
        approvals from Buyer. <br />
        <span style="margin-right: 8px">7.</span> All materials duly processed and supplied against the order should
        conform to the specification/sample provided by the Purchaser. <br />
        <span style="margin-right: 8px">8.</span> Payment by LC or TT onl <br />
        <span style="margin-right: 8px">9.</span> Test report must be submitted before dispatch with â Passâ report
        <br />
        <span style="margin-right: 8px">10.</span> 1/4 yard Head/end cuttings:- <br />
        <div>
          <span style="margin-left: 48px; margin-right: 8px">a.</span> Pls confirm 1/4 yard head/end cuttings of every
          roll from every bulk shipment will be couriered on FOC basis to our office. <br />
          <span style="margin-left: 48px; margin-right: 8px">b.</span> The head/end cuttings need to be sent at the same
          time of bulk goods being exmilled.<br />
          <span style="margin-left: 48px; margin-right: 8px">c.</span> Each head/end cuttings need to have shade#, roll
          #, no.of.yards, inv# on each head/end cuttings. Pls ensure this data need to match bulk shipment rolls. <br />
          <span style="margin-left: 48px; margin-right: 8px">d.</span> We will compare the head/end cuttings against
          bulk roll, if any rolls not matching which will be returned & replaced by mill immdly. <br />
          <span style="margin-left: 48px; margin-right: 8px">e.</span> Mill would bear garment air freight / claims for
          any delay in replacement for such qty which are not matching to the recd head/end cuttings. <br />
        </div>
      </div>
      <br />
      <h4>
        Note: Xinjiang region Cotton should not be used on the fabric/trims and all materials.
        If this fabric is utilized for China market as well, then please make sure all the sampling & bulk yardages must meet the China test parameters too.
        Test report must be arranged for the both the Global & China.
      </h4>
    </div>
  </section>
</template>

<script>
import html2pdf from "html2pdf.js";
import { hasValue, numberWithComma, fixedNumber } from "@/utils/util";
import PoMixin from "@/mixins/PoMixin";

export default {
  props: {
    po: {
      type: Object,
      default: () => {
        return {
          rmPoWorkHistSelectDtoList: [],
          rmPoBomSelectDtoList: [],
          userCompany: {},
          supplier: {},
        };
      },
    },
  },
  mixins: [PoMixin],
  data() {
    return {
      showDetail: true,
      generateTime: 0,
    };
  },
  methods: {
    html2PDF(showDetail) {
      this.generateTime = this.$m();
      this.showDetail = showDetail;
      this.$emit("changeLoading", true);
      //showDetail 변경으로 인한 렌더링 대기시간
      setTimeout(() => {
        html2pdf()
          .from(this.$refs.purchaseOrderDetail.outerHTML)
          .set({
            filename: hasValue(this.po.poNo)
              ? this.po.poNo
              : `${this.po.userCompany?.name?.replace(/[\\/:*?"<>|.]/g, "")}_DraftPO_${this.generateTime.format(
                  "yyyyMMDDHHmm",
                )}`,
            margin: [20, 4, 15, 4], //top, left, bottom, right
            pagebreak: { mode: "avoid-all" }, //, before: ".remarks"
            image: { type: "jpeg", quality: 1 },
            html2canvas: {
              scrollY: 0,
              scale: 3,
              dpi: 300,
              letterRendering: true,
            },
            jsPDF: { unit: "pt", format: "a4", orientation: "landscape" },
          })
          .toPdf()
          .get("pdf")
          .then(pdf => {
            var totalPages = pdf.internal.getNumberOfPages();

            for (let i = 1; i <= totalPages; i++) {
              pdf.setPage(i);
              pdf.setFont("Times New Roman");
              pdf.setTextColor(25, 25, 25);

              const width = pdf.internal.pageSize.getWidth();
              const height = pdf.internal.pageSize.getHeight();

              pdf.setFontSize(12);
              pdf.text(`PO Number : ${this.po.poNo || ""}`, 15, 15);
              // pdf.text("2022-05-26", width - 15, 15, "right");

              pdf.setFontSize(8);
              pdf.text(`page ${i} / ${totalPages}`, width / 2, height - 15, "center");
            }
          })
          .save()
          .then(() => {
            this.$emit("changeLoading", false);
          });
      }, 1 * 1000);
    },
    hasValue,
    numberWithComma,
    fixedNumber,
  },
  computed: {
    status() {
      return this.convertPOStatus(this.po.rmpoStatus);
    },
    isSampled() {
      //RmpoDetail 에서 Convert되어 나오는 text 기준
      return !["PUBLISHED", "RE-PUBLISH"].includes(this.status);
    },
  },
  created() {
    this.generateTime = this.$m();
  },
};
</script>

<style scoped>
.expand-tr + tr {
  border-top: 1px solid #bcbcbc;
}
</style>
