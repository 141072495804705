import { calc, fixedNumber, hasValue, isNumber, numberWithComma } from "@/utils/util";

export default {
  data() {
    return {
      noDataFieldHTML: "<span class='font-error'>Not Included</span>",
    };
  },
  methods: {
    convertPOStatus(status) {
      switch (status) {
        case "PUBLISH":
          return "PUBLISHED";
        case "DRAFT":
          return "DRAFTED";
        case "RETRIEVE":
          return "RETRIEVED";
        default:
          return status;
      }
    },
    getToleranceText(higher, lower) {
      higher = hasValue(higher) ? higher : 0;
      lower = hasValue(lower) ? lower : 0;

      return `+ ${higher} % / - ${lower} %`;
    },
    getGrandTotalPoQty(list, mathFix) {
      if (!hasValue(list)) return;

      //list = bom.mclList
      let sum = 0;
      for (let listIndex = 0; listIndex < list.length; listIndex++) {
        const items = list[listIndex].items;
        for (let itemIndex = 0; itemIndex < items.length; itemIndex++) {
          const item = items[itemIndex];
          sum = calc(sum + (item.poQty || 0));
        }
      }
      return fixedNumber(sum, mathFix);
    },
    getGrandTotalValue(list, mathFix) {
      if (!hasValue(list)) return;

      //list = bom.mclList
      let sum = 0;
      for (let listIndex = 0; listIndex < list.length; listIndex++) {
        const items = list[listIndex].items;
        for (let itemIndex = 0; itemIndex < items.length; itemIndex++) {
          const item = items[itemIndex];
          sum = calc(sum + (item.amount || 0));
        }
      }
      return fixedNumber(sum, mathFix);
    },
  },
  computed: {
    hasFullPaymentInfo() {
      return [this.po.termPayment, this.po.termPeriod, this.po.termDay].every(hasValue);
    },
    termPaymentText() {
      if (this.hasFullPaymentInfo) {
        return `${this.po.termPayment} | SHIPMENT ${this.po.termPeriod} ${this.po.termDay} DAYS`;
      }
      return this.noDataFieldHTML;
    },
    orderQtyText() {
      //uom 별로 po qty의 합
      if (!hasValue(this.po?.rmPoBomSelectDtoList)) return;

      const uoms = {};
      for (let bomIndex = 0; bomIndex < this.po.rmPoBomSelectDtoList.length; bomIndex++) {
        const bom = this.po.rmPoBomSelectDtoList[bomIndex]; //area
        const { mclList } = bom;

        if (Array.isArray(mclList) && mclList.length > 0) {
          for (let mclListIndex = 0; mclListIndex < mclList.length; mclListIndex++) {
            const list = mclList[mclListIndex];
            const items = list.items;

            for (let mclIndex = 0; mclIndex < items.length; mclIndex++) {
              const mcl = items[mclIndex];
              const uom = mcl.fwCwUom;
              const poQty = mcl.poQty;

              if (hasValue(uom)) {
                if (!Object.keys(uoms).includes(uom)) uoms[uom] = 0;
                uoms[uom] += poQty;
              }
            }
          }
        }
      }

      const keys = Object.keys(uoms).sort();
      if (keys.length === 0) return "NO DATA";

      let orderQtyText = "";
      for (let keyIndex = 0; keyIndex < keys.length; keyIndex++) {
        const uom = fixedNumber(uoms[keys[keyIndex]], this.$mathFix, true);
        if (keyIndex !== 0) orderQtyText += `\r\n`;
        orderQtyText += `${numberWithComma(uom)} (${keys[keyIndex]})`;
      }

      return orderQtyText;
    },
    amountText() {
      if (!hasValue(this.po?.rmPoBomSelectDtoList)) return;

      let sum = 0;
      for (let bomIndex = 0; bomIndex < this.po.rmPoBomSelectDtoList.length; bomIndex++) {
        const bom = this.po.rmPoBomSelectDtoList[bomIndex]; //area
        const { mclList } = bom;

        if (Array.isArray(mclList) && mclList.length > 0) {
          for (let mclListIndex = 0; mclListIndex < mclList.length; mclListIndex++) {
            const list = mclList[mclListIndex];
            const items = list.items;

            for (let mclIndex = 0; mclIndex < items.length; mclIndex++) {
              const mcl = items[mclIndex];
              const totalAmount = mcl.amount;

              sum = calc(sum + (totalAmount || 0));
            }
          }
        }
      }

      if (sum === 0) return "NO DATA";
      return sum;
    },
    changeAmountText() {
      if (!hasValue(this.po?.changeAmountInfo) || !hasValue(this.po?.changeAmountUom)) return "NO DATA";
      return `${this.po.changeAmountInfo} ${this.po.changeAmount || 0} ${
        this.po.changeAmountUom === "*"
          ? this.$store.getters["code/getCurrencyCode"](this.po.currency)
          : this.po.changeAmountUom
      }`;
    },
    finalAmountText() {
      let amount = this.amountText;
      const changeAmount = this.po.changeAmount || 0;

      if (isNumber(amount) && hasValue(this.po.changeAmountInfo) && hasValue(this.po.changeAmountUom)) {
        if (this.po.changeAmountUom === "%") {
          if (this.po.changeAmountInfo === "+") amount = calc(amount + amount * (changeAmount / 100));
          else if (this.po.changeAmountInfo === "-") amount = calc(amount - amount * (changeAmount / 100));
        }
        if (this.po.changeAmountUom === "*") {
          if (this.po.changeAmountInfo === "+") amount = calc(amount + changeAmount);
          else if (this.po.changeAmountInfo === "-") amount = calc(amount - changeAmount);
        }
      }
      return amount;
    },
  },
};
