<template>
  <div class="purchase-order-detail">
    <div class="title d-flex">
      <router-link :to="{ name: 'issuedRMPO' }">
        <i class="fa-solid fa-angle-left" style="margin-right: 10px" />
      </router-link>

      <h1>PURCHASE ORDER</h1>
      <img
        :src="require('images/ico_pdf.png')"
        class="align-self-center ml-1 pointer"
        style="width: auto; height: 24px"
        v-if="!editMode"
        @click="checkPDFOption" />
      <template v-if="isReceived"> </template>
      <template v-else-if="po.rmpoStatus === 'PUBLISH' || po.rmpoStatus === 'RE-PUBLISH'">
        <v-spacer />
        <v-btn color="secondary" :elevation="0" @click="retrieveClicked">RETRIEVE</v-btn>
      </template>
      <template v-else-if="!editMode">
        <v-spacer />
        <v-btn
          v-if="po.rmpoStatus === 'RETRIEVE'"
          color="secondary"
          :elevation="0"
          :disabled="!canPublish"
          @click="rePublishClicked">
          RE-PUBLISH
        </v-btn>
        <v-btn v-else color="secondary" :elevation="0" :disabled="!canPublish" @click="publishClicked"> PUBLISH </v-btn>
        <v-btn color="secondary" :elevation="0" @click="changeEditMode(true)">MODIFY</v-btn>
      </template>
      <template v-else>
        <v-btn color="secondary" v-if="!createMode" :elevation="0" @click="discardClicked"> DISCARD </v-btn>
        <v-spacer />
        <v-btn color="secondary" :elevation="0" @click="save" :loading="saveLoading"> SAVE </v-btn>
        <v-btn color="secondary" :elevation="0" @click="changeEditMode(false)">CANCEL</v-btn>
      </template>
    </div>

    <div class="contents">
      <div class="purchase-order-information">
        <div class="d-flex gutter-mx-3 top">
          <div class="basic-info">
            <div>PO Number : {{ hasValue(po.poNo) ? po.poNo : "" }}</div>
            <div class="d-flex" v-if="!isReceived">
              <div>Modified :</div>
              <div style="margin-left: 4px" v-html="workHistoryText"></div>
            </div>
          </div>

          <div class="status" v-if="!createMode">
            <div class="custom-table">
              <table>
                <thead>
                  <tr>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{{ convertPOStatus(po.rmpoStatus) }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div class="company-info">
            <div class="custom-table">
              <table>
                <thead>
                  <tr>
                    <th>NAME</th>
                    <th>PO issued by (Bill To)</th>
                    <th>Supplier</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Company</td>
                    <td>{{ po.userCompany.name }}</td>
                    <td>{{ po.supplier.name }}</td>
                  </tr>
                  <tr>
                    <td>Address</td>
                    <td>
                      {{
                        hasValue(po.userCompany.cityCode)
                          ? `${po.userCompany.address}, ${po.userCompany.cityCode}`
                          : `${po.userCompany.address}`
                      }}
                    </td>
                    <td>
                      {{
                        hasValue(po.supplier.cityCode)
                          ? `${po.supplier.address}, ${po.supplier.cityCode}`
                          : `${po.supplier.address}`
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td>Contact</td>
                    <td>
                      {{
                        `Tel: ${(po.userCompany.contactCallCountry || "") + " " + (po.userCompany.contactCall || "")} /
                        Mail: ${po.userCompany.contactMail || "" || ""}`
                      }}
                    </td>
                    <td>
                      {{
                        `Tel: ${(po.supplier.contactCallCountry || "") + " " + (po.supplier.contactCall || "")} / 
                        Mail: ${po.supplier.contactMail || "" || ""}`
                      }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div class="d-flex gutter-mx-3 bottom">
          <div class="country-info">
            <div class="custom-table">
              <table>
                <thead>
                  <tr>
                    <th colspan="2">COUNTRY</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Origin</td>
                    <td
                      v-if="!editMode"
                      v-html="hasValue(po.cntryOrg) ? po.cntryOrg : noDataFieldHTML"
                      :class="{ required: !hasValue(po.cntryOrg) }"></td>
                    <td v-else :class="{ required: !hasValue(po.cntryOrg) }">
                      <v-autocomplete
                        dense
                        solo
                        hide-details
                        flat
                        outlined
                        :items="$store.state.code.common.country"
                        item-text="itemCd"
                        item-value="itemCd"
                        v-model="po.cntryOrg">
                        <template v-slot:label>
                          <span class="font-error">SELECT</span>
                        </template>
                        <template v-slot:append>
                          <v-icon> mdi-chevron-down </v-icon>
                        </template>
                      </v-autocomplete>
                    </td>
                  </tr>
                  <tr>
                    <td>Destination</td>
                    <td
                      v-if="!editMode"
                      v-html="hasValue(po.cntryDst) ? po.cntryDst : noDataFieldHTML"
                      :class="{ required: !hasValue(po.cntryDst) }"></td>
                    <td v-else :class="{ required: !hasValue(po.cntryDst) }">
                      <v-autocomplete
                        dense
                        solo
                        hide-details
                        flat
                        outlined
                        :items="$store.state.code.common.country"
                        item-text="itemCd"
                        item-value="itemCd"
                        v-model="po.cntryDst">
                        <template v-slot:label>
                          <span class="font-error">SELECT</span>
                        </template>
                        <template v-slot:append>
                          <v-icon> mdi-chevron-down </v-icon>
                        </template>
                      </v-autocomplete>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div class="port-info">
            <div class="custom-table">
              <table>
                <thead>
                  <tr>
                    <th colspan="2">PORT</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Loading</td>
                    <td
                      v-if="!editMode"
                      v-html="hasValue(po.portLoad) ? po.portLoad : noDataFieldHTML"
                      :class="{ required: !hasValue(po.portLoad) }"></td>
                    <td v-else :class="{ required: !hasValue(po.portLoad) }">
                      <v-text-field
                        solo
                        flat
                        dense
                        outlined
                        hide-details
                        background-color="white"
                        v-model="po.portLoad">
                        <template v-slot:label>
                          <span class="font-error">INPUT</span>
                        </template>
                      </v-text-field>
                    </td>
                  </tr>
                  <tr>
                    <td>Discharging</td>
                    <td
                      v-if="!editMode"
                      v-html="hasValue(po.portDis) ? po.portDis : noDataFieldHTML"
                      :class="{ required: !hasValue(po.portDis) }"></td>
                    <td v-else :class="{ required: !hasValue(po.portDis) }">
                      <v-text-field solo flat dense outlined hide-details background-color="white" v-model="po.portDis">
                        <template v-slot:label>
                          <span class="font-error">INPUT</span>
                        </template>
                      </v-text-field>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div class="date-info">
            <div class="custom-table">
              <table>
                <thead>
                  <tr>
                    <th colspan="2">DATE</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>EX-Mill</td>

                    <td
                      v-if="!editMode && !hasValue(po.exMill)"
                      v-html="noDataFieldHTML"
                      :class="{ required: !hasValue(po.exMill) }"></td>
                    <td v-if="!editMode && hasValue(po.exMill)">{{ $m(po.exMill).format("yyyy-MM-DD") }}</td>
                    <td v-if="editMode" :class="{ required: !hasValue(po.exMill) }">
                      <input-date
                        :open.sync="exMillDateShow"
                        :date.sync="po.exMill"
                        :required="true"
                        requiredText="SELECT" />
                    </td>
                  </tr>
                  <tr>
                    <td>Ship</td>
                    <td
                      v-if="!editMode && !hasValue(po.shipDate)"
                      v-html="noDataFieldHTML"
                      :class="{ required: !hasValue(po.shipDate) }"></td>
                    <td v-if="!editMode && hasValue(po.shipDate)">{{ $m(po.shipDate).format("yyyy-MM-DD") }}</td>
                    <td v-if="editMode" :class="{ required: !hasValue(po.shipDate) }">
                      <input-date
                        :open.sync="shipDateDateShow"
                        :date.sync="po.shipDate"
                        :required="true"
                        requiredText="SELECT" />
                    </td>
                  </tr>
                  <tr>
                    <td>In House</td>
                    <td
                      v-if="!editMode && !hasValue(po.inHouse)"
                      v-html="noDataFieldHTML"
                      :class="{ required: !hasValue(po.inHouse) }"></td>
                    <td v-if="!editMode && hasValue(po.inHouse)">{{ $m(po.inHouse).format("yyyy-MM-DD") }}</td>
                    <td v-if="editMode" :class="{ required: !hasValue(po.inHouse) }">
                      <input-date
                        :open.sync="inHouseDateShow"
                        :date.sync="po.inHouse"
                        :required="true"
                        requiredText="SELECT" />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div class="term-info">
            <div class="custom-table">
              <table>
                <thead>
                  <tr>
                    <th colspan="2">TERM</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Delivery</td>
                    <td
                      v-if="!editMode"
                      v-html="hasValue(po.termDelivery) ? po.termDelivery : noDataFieldHTML"
                      :class="{ required: !hasValue(po.termDelivery) }"></td>
                    <td v-else :class="{ required: !hasValue(po.termDelivery) }">
                      <v-autocomplete
                        dense
                        solo
                        hide-details
                        flat
                        outlined
                        :items="$store.state.code.common.termsOfDelivery"
                        item-text="itemName"
                        item-value="itemName"
                        v-model="po.termDelivery">
                        <template v-slot:label>
                          <span class="font-error">SELECT</span>
                        </template>
                        <template v-slot:append>
                          <v-icon> mdi-chevron-down </v-icon>
                        </template>
                      </v-autocomplete>
                    </td>
                  </tr>
                  <tr>
                    <td>Payment</td>
                    <td v-if="!editMode" v-html="termPaymentText" :class="{ required: !hasFullPaymentInfo }" />
                    <td v-else>
                      <div class="d-flex justify-space-around align-center">
                        <v-autocomplete
                          :class="{ required: !hasValue(po.termPayment) }"
                          dense
                          solo
                          hide-details
                          flat
                          outlined
                          :items="$store.state.code.common.termsOfPayment"
                          item-text="itemName"
                          item-value="itemName"
                          v-model="po.termPayment">
                          <template v-slot:label>
                            <span class="font-error">SELECT</span>
                          </template>
                          <template v-slot:append>
                            <v-icon> mdi-chevron-down </v-icon>
                          </template>
                        </v-autocomplete>
                        SHIPMENT
                        <v-autocomplete
                          :class="{ required: !hasValue(po.termPeriod) }"
                          dense
                          solo
                          hide-details
                          flat
                          outlined
                          :items="['AFTER', 'BEFORE']"
                          v-model="po.termPeriod">
                          <template v-slot:label>
                            <span class="font-error">SELECT</span>
                          </template>
                          <template v-slot:append>
                            <v-icon> mdi-chevron-down </v-icon>
                          </template>
                        </v-autocomplete>
                      </div>
                      <div class="d-flex align-center mt-1">
                        <v-text-field
                          :class="{ required: !hasValue(po.termDay) }"
                          solo
                          flat
                          dense
                          outlined
                          hide-details
                          background-color="white"
                          type="number"
                          v-model.number="po.termDay">
                          <template v-slot:label>
                            <span class="font-error">INPUT</span>
                          </template>
                        </v-text-field>
                        DAYS
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div class="etc-info">
            <div class="custom-table">
              <table>
                <thead>
                  <tr>
                    <th colspan="4">CONDITION</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="th">Ship Mode</td>
                    <td
                      v-if="!editMode"
                      v-html="hasValue(po.shipMode) ? po.shipMode : noDataFieldHTML"
                      :class="{ required: !hasValue(po.shipMode) }"></td>
                    <td v-else :class="{ required: !hasValue(po.shipMode) }">
                      <v-autocomplete
                        dense
                        solo
                        hide-details
                        flat
                        outlined
                        :items="$store.state.code.common.shipMode"
                        item-text="itemName"
                        item-value="itemName"
                        v-model="po.shipMode">
                        <template v-slot:label>
                          <span class="font-error">SELECT</span>
                        </template>
                        <template v-slot:append>
                          <v-icon> mdi-chevron-down </v-icon>
                        </template>
                      </v-autocomplete>
                    </td>
                    <td class="th">Ship Tolerance</td>
                    <td
                      v-if="!editMode"
                      v-html="getToleranceText(po.shipToleranceHigher, po.shipToleranceLower)"
                      :class="{ required: !hasValue(po.shipToleranceHigher) || !hasValue(po.shipToleranceLower) }"></td>
                    <td v-else>
                      <div class="ship-tolerance-input">
                        +
                        <v-text-field
                          :class="{ required: !hasValue(po.shipToleranceHigher) }"
                          solo
                          flat
                          dense
                          outlined
                          hide-details
                          background-color="white"
                          type="number"
                          v-model.number="po.shipToleranceHigher">
                          <template v-slot:label>
                            <span class="font-error">INPUT</span>
                          </template>
                        </v-text-field>
                        %
                      </div>
                      <span>/</span>
                      <div class="ship-tolerance-input">
                        -
                        <v-text-field
                          :class="{ required: !hasValue(po.shipToleranceLower) }"
                          solo
                          flat
                          dense
                          outlined
                          hide-details
                          background-color="white"
                          type="number"
                          v-model.number="po.shipToleranceLower">
                          <template v-slot:label>
                            <span class="font-error">INPUT</span>
                          </template>
                        </v-text-field>
                        %
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="th">Currency</td>
                    <td
                      v-if="!editMode"
                      v-html="hasValue(po.currency) ? po.currency : noDataFieldHTML"
                      :class="{ required: !hasValue(po.currency) }"></td>
                    <td v-else :class="{ required: !hasValue(po.currency) }">
                      <v-autocomplete
                        dense
                        solo
                        hide-details
                        flat
                        outlined
                        :items="$store.state.code.common.currency"
                        item-text="itemName"
                        item-value="itemName"
                        v-model="po.currency">
                        <template v-slot:label>
                          <span class="font-error">SELECT</span>
                        </template>
                        <template v-slot:append>
                          <v-icon> mdi-chevron-down </v-icon>
                        </template>
                      </v-autocomplete>
                    </td>
                    <td class="th">Partial Shipment</td>
                    <td
                      v-if="!editMode"
                      v-html="hasValue(po.partialShipment) ? po.partialShipment : noDataFieldHTML"
                      :class="{ required: !hasValue(po.partialShipment) }"></td>
                    <td v-else :class="{ required: !hasValue(po.partialShipment) }">
                      <v-autocomplete
                        dense
                        solo
                        hide-details
                        flat
                        outlined
                        :items="$store.state.code.common.partialShipment"
                        item-text="itemName"
                        item-value="itemName"
                        v-model="po.partialShipment">
                        <template v-slot:label>
                          <span class="font-error">SELECT</span>
                        </template>
                        <template v-slot:append>
                          <v-icon> mdi-chevron-down </v-icon>
                        </template>
                      </v-autocomplete>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <div class="bom-list">
        <div class="bom" v-for="(bom, bomIndex) in po.rmPoBomSelectDtoList" :key="`bom ${bomIndex}`">
          <div class="bom-title d-flex">
            <span v-if="!editMode" :class="{ required: !hasValue(bom.areaName) }"> {{ bom.areaName }} </span>
            <v-text-field
              v-else
              solo
              flat
              dense
              outlined
              hide-details
              background-color="white"
              v-model="bom.areaName"
              :class="{ required: !hasValue(bom.areaName) }">
              <template v-slot:label>
                <span class="font-error">INPUT</span>
              </template>
            </v-text-field>
            <span class="highlight">{{ (bomIndex + 1).toString().padStart(3, 0) }}</span>
            <v-spacer />
            <v-btn
              outlined
              color="secondary"
              v-if="editMode && (hasValue(bom.mclUUID) || (Array.isArray(bom.mclList) && bom.mclList.length === 0))"
              @click="loadMCL(bom)">
              LOAD MCL
            </v-btn>
            <v-btn class="ml-2" color="secondary" outlined v-if="editMode" @click="deleteAreaClicked(bom)">
              Remove
            </v-btn>
          </div>

          <div
            class="info-card-list d-flex"
            v-if="hasValue(bom.mclUUID) && Array.isArray(bom.mclList) && bom.mclList.length > 0">
            <!-- year/season -->
            <div class="info-card">
              <p>YEAR / SEASON</p>
              <p>{{ `${bom.bomYear} / ${bom.season}` }}</p>
            </div>
            <!-- buyer brand -->
            <div class="info-card">
              <p>BUYER BRAND</p>
              <p>{{ `${bom.offerBrand}` }}</p>
            </div>
            <!-- gender -->
            <div class="info-card">
              <p>GENDER</p>
              <p>{{ `${bom.gender}` }}</p>
            </div>
            <!-- garment type -->
            <div class="info-card">
              <p>GARMENT TYPE</p>
              <p>{{ `${bom.garmentType}` }}</p>
            </div>
            <!-- fabric type -->
            <div class="info-card">
              <p>FABRIC TYPE</p>
              <p>{{ `${bom.fabricType}` }}</p>
            </div>
            <!-- design no -->
            <div class="info-card">
              <p>DESIGN NUMBER</p>
              <p>{{ `${bom.designNo}` }}</p>
            </div>
            <!-- style no -->
            <div class="info-card">
              <p>STYLE NUMBER</p>
              <p>{{ hasValue(bom.styleNo) ? bom.styleNo.replaceAll("|$|", ", ") : "" }}</p>
            </div>
            <!-- bom title -->
            <div class="info-card">
              <p>BOM TITLE</p>
              <p>{{ `${bom.coverName}` }}</p>
            </div>
          </div>

          <div class="mcl-list-table">
            <div class="custom-table">
              <table>
                <thead>
                  <tr>
                    <th>NO.</th>
                    <th>Mill/ITEM #</th>
                    <th>RD #</th>
                    <th>Item Name</th>
                    <th>Color</th>
                    <th>CW/SIZE</th>
                    <th>COMPOSITION<br />ITEM DETAIL</th>
                    <th>Unit Price</th>
                    <th>UOM (Supplier)</th>
                    <th>PO Qty</th>
                    <th>Total Value</th>
                    <th>Qty Data Details</th>
                    <th>Remark</th>
                    <th v-if="editMode"></th>
                  </tr>
                </thead>
                <template v-for="(mcl, mclIndex) in bom.mclList">
                  <tbody :key="`tbody mcl ${bom.bomUUID} ${mcl.mtrlUUID} ${bomIndex} ${mclIndex}`">
                    <template v-for="(mclItem, mclItemIndex) in mcl.items">
                      <tr
                        :key="`tr mclItem ${bom.bomUUID} ${mcl.mtrlUUID} ${mclItem.rmpoMclId} ${bomIndex} ${mclIndex} ${mclItemIndex} ${mclItem.tempRegDt}`">
                        <!-- No -->
                        <td>{{ getNO(bom.mclList, mclIndex, mclItemIndex) }}</td>
                        <!-- itemNo -->
                        <td>{{ mclItem.itemNo }}</td>
                        <!-- rdNo -->
                        <td>{{ mclItem.rdNo }}</td>
                        <!-- itemName -->
                        <td>{{ mclItem.itemName }}</td>
                        <!-- color -->
                        <td :class="{ required: !hasValue(mclItem.color) }">
                          <span v-if="!editMode">
                            {{ mclItem.color }}
                          </span>
                          <v-text-field
                            v-else
                            solo
                            flat
                            dense
                            outlined
                            hide-details
                            background-color="white"
                            v-model="mclItem.color">
                            <template v-slot:label>
                              <span class="font-error">INPUT</span>
                            </template>
                          </v-text-field>
                        </td>
                        <!-- cw -->
                        <td>
                          <v-text-field
                            v-if="editMode && !hasValue(bom.mclUUID)"
                            solo
                            flat
                            dense
                            outlined
                            hide-details
                            background-color="white"
                            v-model="mclItem.cw">
                          </v-text-field>

                          <span v-else>
                            {{ mclItem.cw }}
                          </span>
                        </td>
                        <!-- composition/itemDetail -->
                        <td v-html="mclItem.mtrlCompRate"></td>
                        <!-- unitPrice -->
                        <td :class="{ required: !hasValue(mclItem.unitPrice) }">
                          <span v-if="!editMode">
                            {{ numberWithComma(mclItem.unitPrice) }}
                          </span>

                          <span v-else-if="editMode && hasValue(bom.mclUUID) && mclItem.isQtyDtl === 'Y'">
                            <!-- use Detail Data가 체크되었다면 -->
                            {{ numberWithComma(mclItem.unitPrice) }}
                          </span>

                          <v-text-field
                            v-else
                            solo
                            flat
                            dense
                            outlined
                            hide-details
                            background-color="white"
                            @keyup="changePoQty(mclItem)"
                            @change="changePoQty(mclItem)"
                            type="number"
                            v-model.number="mclItem.unitPrice">
                            <template v-slot:label>
                              <span class="font-error">INPUT</span>
                            </template>
                          </v-text-field>
                        </td>
                        <!-- uom -->
                        <td :class="{ required: !hasValue(mclItem.fwCwUom) }">
                          <span v-if="!editMode">{{ mclItem.fwCwUom }}</span>
                          <v-autocomplete
                            v-else
                            dense
                            solo
                            hide-details
                            flat
                            outlined
                            :items="
                              mclItem.mtrlCate === $store.getters['code/getMtrlCateFabricCode']
                                ? $store.state.code.common.uomFabric
                                : $store.state.code.common.uomOther
                            "
                            item-text="itemName"
                            item-value="itemName"
                            v-model="mclItem.fwCwUom">
                            <template v-slot:label>
                              <span class="font-error">SELECT</span>
                            </template>
                            <template v-slot:append>
                              <v-icon> mdi-chevron-down </v-icon>
                            </template>
                          </v-autocomplete>
                        </td>
                        <!-- poQty -->
                        <td :class="{ required: !hasValue(bom.mclUUID) && !hasValue(mclItem.poQty) }">
                          <span v-if="!editMode || hasValue(bom.mclUUID)">
                            {{ numberWithComma(mclItem.poQty) }}
                          </span>

                          <v-text-field
                            v-else
                            solo
                            flat
                            dense
                            outlined
                            hide-details
                            background-color="white"
                            type="number"
                            @keyup="changePoQty(mclItem)"
                            @change="changePoQty(mclItem)"
                            v-model.number="mclItem.poQty">
                            <template v-slot:label>
                              <span class="font-error">INPUT</span>
                            </template>
                          </v-text-field>
                        </td>
                        <!-- total value -->
                        <td>
                          {{ numberWithComma(fixedNumber(mclItem.amount, $mathFix)) }}
                        </td>
                        <!-- qty Detail Data -->
                        <td>
                          <v-btn
                            x-small
                            text
                            color="blue1"
                            @click="mclItem.expandShow = !mclItem.expandShow"
                            :disabled="!hasValue(bom.mclUUID)">
                            {{ !mclItem.expandShow ? "VIEW" : "CLOSE" }}
                          </v-btn>
                        </td>
                        <!-- remark -->
                        <td>
                          <span v-if="!editMode">{{ mclItem.remark }}</span>
                          <v-text-field
                            v-else
                            solo
                            flat
                            dense
                            outlined
                            hide-details
                            background-color="white"
                            v-model="mclItem.remark" />
                        </td>
                        <!-- delete row -->
                        <td v-if="editMode">
                          <div class="d-flex">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  color="secondary"
                                  outlined
                                  fab
                                  x-small
                                  v-bind="attrs"
                                  v-on="on"
                                  @click="copyMclItem(bom, mcl.items, mclItem)">
                                  <i class="fa-solid fa-clone"></i>
                                </v-btn>
                              </template>
                              <span>Item copy</span>
                            </v-tooltip>

                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  color="secondary"
                                  outlined
                                  fab
                                  x-small
                                  @click="deleteMclItemClicked(bom, mcl.items, mclItem)"
                                  v-bind="attrs"
                                  v-on="on">
                                  <v-icon> mdi-minus </v-icon>
                                </v-btn>
                              </template>
                              <span>Delete</span>
                            </v-tooltip>
                          </div>
                        </td>
                      </tr>
                      <!-- #region expand-row -->
                      <tr
                        :key="`tr mclItem expand-row ${bom.bomUUID} ${mcl.mtrlUUID} ${mclItem.rmpoMclId} ${bomIndex} ${mclIndex} ${mclItemIndex} ${mclItem.tempRegDt}`"
                        v-if="mclItem.expandShow">
                        <td colspan="100" class="expand-row text-start">
                          <div class="table-info d-flex">
                            <span>QUANTITY DETAIL DATA</span>
                            <v-checkbox
                              v-if="editMode"
                              dense
                              color="secondary"
                              label="USE DETAIL DATA"
                              :disabled="
                                mclItem.rmPoMclQtyDtlSelectDtoList.length === 1 &&
                                [
                                  mclItem.rmPoMclQtyDtlSelectDtoList[0].size,
                                  mclItem.rmPoMclQtyDtlSelectDtoList[0].market,
                                  mclItem.rmPoMclQtyDtlSelectDtoList[0].color,
                                ].every(v => v === 'All')
                              "
                              true-value="Y"
                              false-value="N"
                              @change="changeIsQtyDtl(mclItem)"
                              v-model="mclItem.isQtyDtl" />
                          </div>
                          <!-- #region useDetailData is False  -->
                          <div class="custom-table" v-if="mclItem.isQtyDtl === 'N'">
                            <table>
                              <thead>
                                <tr>
                                  <th>NET YY</th>
                                  <th>LOSS %</th>
                                  <th>GROSS YY</th>
                                  <th>REQUIRED QTY</th>
                                  <th>STOCK QTY</th>
                                  <th>ISSUED QTY</th>
                                  <th>BALANCE QTY</th>
                                  <th>UNIT PRICE</th>
                                  <th>PO QTY</th>
                                  <th>TOTAL VALUE</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>
                                    {{
                                      numberWithComma(
                                        mclItem.rmPoMclQtyDtlSelectDtoList[0]
                                          ? mclItem.rmPoMclQtyDtlSelectDtoList[0].netYy
                                          : 0,
                                      )
                                    }}
                                  </td>
                                  <td>
                                    {{
                                      numberWithComma(
                                        mclItem.rmPoMclQtyDtlSelectDtoList[0]
                                          ? mclItem.rmPoMclQtyDtlSelectDtoList[0].loss
                                          : 0,
                                      )
                                    }}
                                  </td>
                                  <td>
                                    {{
                                      numberWithComma(
                                        mclItem.rmPoMclQtyDtlSelectDtoList[0]
                                          ? fixedNumber(mclItem.rmPoMclQtyDtlSelectDtoList[0].grossYy, $mathFix, true)
                                          : 0,
                                      )
                                    }}
                                  </td>
                                  <td>
                                    {{
                                      numberWithComma(
                                        fixedNumber(
                                          mclItem.rmPoMclQtyDtlSelectDtoList
                                            .map(i => i.reqQty)
                                            .reduce((partialSum, a) => partialSum + a, 0),
                                          $mathFix,
                                          true,
                                        ),
                                      )
                                    }}
                                  </td>
                                  <td>
                                    {{
                                      numberWithComma(
                                        mclItem.rmPoMclQtyDtlSelectDtoList
                                          .map(i => i.stockQty)
                                          .reduce((partialSum, a) => partialSum + a, 0),
                                      )
                                    }}
                                  </td>
                                  <td>
                                    {{
                                      numberWithComma(
                                        fixedNumber(
                                          mclItem.rmPoMclQtyDtlSelectDtoList
                                            .map(i => i.issuedQty)
                                            .reduce((partialSum, a) => partialSum + a, 0),
                                          $mathFix,
                                          true,
                                        ),
                                      )
                                    }}
                                  </td>
                                  <td>
                                    {{
                                      numberWithComma(
                                        fixedNumber(
                                          mclItem.rmPoMclQtyDtlSelectDtoList
                                            .map(i => i.balanceQty)
                                            .reduce((partialSum, a) => partialSum + a, 0),
                                          $mathFix,
                                          true,
                                        ),
                                      )
                                    }}
                                  </td>
                                  <td>
                                    {{ numberWithComma(mclItem.unitPrice) }}
                                  </td>
                                  <!-- poQty -->
                                  <td :class="{ required: !hasValue(mclItem.poQty) }">
                                    <span v-if="!editMode">
                                      {{ numberWithComma(mclItem.poQty) }}
                                    </span>

                                    <v-text-field
                                      v-else
                                      solo
                                      flat
                                      dense
                                      outlined
                                      hide-details
                                      background-color="white"
                                      type="number"
                                      @keyup="changePoQty(mclItem)"
                                      @change="changePoQty(mclItem)"
                                      v-model.number="mclItem.poQty">
                                      <template v-slot:label>
                                        <span class="font-error">INPUT</span>
                                      </template>
                                    </v-text-field>
                                  </td>
                                  <!-- amount -->
                                  <td>
                                    {{ numberWithComma(fixedNumber(mclItem.amount, $mathFix, true)) }}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <!-- #endregion -->
                          <!-- #region useDetailData is True -->
                          <div class="custom-table" v-else>
                            <table>
  <thead>
    <tr>
      <th>NO.</th>
      <th>MARKET</th>
      <th>GARMENT SIZE</th>
      <th>GARMENT COLOR</th>

      <th>NET YY</th>
      <th>LOSS %</th>
      <th>GROSS YY</th>
      <th>REQUIRED QTY</th>
      <th>STOCK QTY</th>
      <th>ISSUED QTY</th>
      <th>BALANCE QTY</th>

      <th>UNIT PRICE</th>
      <th>PO QTY</th>
      <th>TOTAL VALUE</th>
      <th>REMARK</th>
    </tr>
  </thead>
  <tbody>
    <tr
    v-for="(qty, qtyIndex) in mclItem.rmPoMclQtyDtlSelectDtoList"
    :key="`tr mclItem expand-row detail ${bom.bomUUID} ${mcl.mclUUID} ${qty.rmpoUUID} ${mclItem.tempRegDt} ${bomIndex} ${mclIndex} ${mclItemIndex} ${qtyIndex}`">
    <td>
      {{ qtyIndex + 1 }}
    </td>
    <td>
      {{ qty.market === "All" ? "" : qty.market }}
    </td>
    <td>
      {{ qty.size === "All" ? "" : qty.size }}
    </td>
    <td>
      {{ qty.color === "All" ? "" : qty.color }}
    </td>

    <td>
      {{ numberWithComma(qty.netYy) }}
    </td>
    <td>
      {{ numberWithComma(qty.loss) }}
    </td>
    <td>
      {{ numberWithComma(fixedNumber(qty.grossYy, $mathFix, true)) }}
    </td>
    <td>
      {{ numberWithComma(fixedNumber(qty.reqQty, $mathFix, true)) }}
    </td>
    <td>
      {{ numberWithComma(qty.stockQty) }}
    </td>
    <td>
      {{ numberWithComma(fixedNumber(qty.issuedQty, $mathFix, true)) }}
    </td>
    <td>
      {{ numberWithComma(fixedNumber(qty.balanceQty, $mathFix, true)) }}
    </td>

    <td :class="{ required: !hasValue(qty.unitPrice) }">
      <span v-if="!editMode">
        {{ numberWithComma(qty.unitPrice) }}
      </span>

      <v-text-field
      v-else
      solo
      flat
      dense
      outlined
      hide-details
      background-color="white"
      @keyup="changeQtyDtl(qty, mclItem, true)"
      @change="changeQtyDtl(qty, mclItem, true)"
      type="number"
      v-model.number="qty.unitPrice">
      <template v-slot:label>
        <span class="font-error">INPUT</span>
      </template>
    </v-text-field>
  </td>
  <td :class="{ required: !hasValue(qty.poQty) }">
    <span v-if="!editMode">
      {{ numberWithComma(qty.poQty) }}
    </span>

    <v-text-field
    v-else
    solo
    flat
    dense
    outlined
    hide-details
    background-color="white"
    @keyup="changeQtyDtl(qty, mclItem)"
    @change="changeQtyDtl(qty, mclItem)"
    type="number"
    v-model.number="qty.poQty">
    <template v-slot:label>
      <span class="font-error">INPUT</span>
    </template>
  </v-text-field>
</td>
<td>
  {{ numberWithComma(fixedNumber(qty.amount, $mathFix, true)) }}
</td>
<td>
  <span v-if="!editMode"> {{ qty.remark }} </span>
  <v-text-field
  v-else
  solo
  flat
  dense
  outlined
  hide-details
  background-color="white"
  v-model="qty.remark">
</v-text-field>
</td>
</tr>

<!-- expand total -->
<tr class="expand-total">
  <td colspan="4">TOTAL</td>
  <td></td>
  <td></td>
  <td></td>
  REQUIRED QTY
  <td>
    {{
      numberWithComma(
      fixedNumber(
      mclItem.rmPoMclQtyDtlSelectDtoList
      .map(i => i.reqQty)
      .reduce((partialSum, a) => partialSum + a, 0),
      $mathFix,
      true,
      ),
      )
    }}
  </td>
  <!-- STOCK QTY -->
  <td>
    {{
      numberWithComma(
      mclItem.rmPoMclQtyDtlSelectDtoList
      .map(i => i.stockQty)
      .reduce((partialSum, a) => partialSum + a, 0),
      )
    }}
  </td>
  <!-- ISSUED QTY -->
  <td>
    {{
      numberWithComma(
      fixedNumber(
      mclItem.rmPoMclQtyDtlSelectDtoList
      .map(i => i.issuedQty)
      .reduce((partialSum, a) => partialSum + a, 0),
      $mathFix,
      true,
      ),
      )
    }}
  </td>
  <!-- BALANCE QTY -->
  <td>
    {{
      numberWithComma(
      fixedNumber(
      mclItem.rmPoMclQtyDtlSelectDtoList
      .map(i => i.balanceQty)
      .reduce((partialSum, a) => partialSum + a, 0),
      $mathFix,
      true,
      ),
      )
    }}
  </td>
  <td></td>
  <!-- PO QTY -->
  <td>
    {{ numberWithComma(mclItem.poQty) }}
  </td>
  <!-- TOTAL VALUE -->
  <td>
    {{ numberWithComma(fixedNumber(mclItem.amount, $mathFix, true)) }}
  </td>
  <td></td>
</tr>
</tbody>
</table>
</div>
<!-- #endregion -->
</td>
</tr>
<!-- #endregion -->
</template>
<!-- subTotal -->
<tr class="sub-total" v-if="mcl.items.length > 1">
  <td colspan="7">Sub Total of Items</td>
  <td></td>
  <td></td>
  <td>
    {{
      numberWithComma(
      fixedNumber(
      mcl.items.map(i => i.poQty).reduce((partialSum, a) => partialSum + (a || 0), 0),
      $mathFix,
      true,
      ),
      )
    }}
  </td>
  <td></td>
  <td></td>
  <td></td>
  <td v-if="editMode"></td>
</tr>
</tbody>
</template>
<!-- add row -->
<tbody v-if="editMode && !hasValue(bom.mclUUID)">
  <tr>
    <td colspan="100" class="register pointer" @click="addMclItem(bom)">
      <v-btn x-small color="secondary" :elevation="0">ADD</v-btn> NEW ITEM
    </td>
  </tr>
</tbody>
<!-- grandTotal -->
<tbody>
  <tr class="grand-total">
    <td colspan="7">Grand Total of Items</td>
    <td>-</td>
    <td></td>
    <td>{{ numberWithComma(getGrandTotalPoQty(bom.mclList, $mathFix)) }}</td>
    <td>{{ numberWithComma(getGrandTotalValue(bom.mclList, $mathFix)) }}</td>
    <td></td>
    <td></td>
    <td v-if="editMode"></td>
  </tr>
</tbody>
</table>
            </div>
          </div>
        </div>
      </div>

      <div class="addArea d-flex justify-center">
        <v-btn v-if="editMode" outlined color="secondary" @click="addArea">
          + Click here to add items from different buyers or design#
        </v-btn>
      </div>

      <div class="total d-flex">
        <v-spacer></v-spacer>
        <div class="custom-table">
          <table>
            <tbody>
              <tr>
                <td>Order Qty</td>
                <td v-html="orderQtyText"></td>
              </tr>
              <tr>
                <td>Amount{{ hasValue(po.currency) ? ` (${po.currency})` : "" }}</td>
                <td>{{ numberWithComma(fixedNumber(amountText, $mathFix, true)) }}</td>
              </tr>
              <tr>
                <td>Upcharge or Discount (%)</td>
                <td>
                  <span v-if="!editMode">
                    {{ changeAmountText }}
                  </span>

                  <div class="d-flex" v-else>
                    <v-autocomplete
                      dense
                      solo
                      hide-details
                      flat
                      outlined
                      :items="[
                        { text: '+', value: '+' },
                        { text: '-', value: '-' },
                      ]"
                      v-model="po.changeAmountInfo">
                      <template v-slot:label>
                        <span>SELECT</span>
                      </template>
                      <template v-slot:append>
                        <v-icon> mdi-chevron-down </v-icon>
                      </template>
                    </v-autocomplete>

                    <v-text-field
                      solo
                      flat
                      dense
                      outlined
                      hide-details
                      background-color="white"
                      type="number"
                      v-model.number="po.changeAmount">
                      <template v-slot:label>
                        <span class="font-error">INPUT</span>
                      </template>
                    </v-text-field>

                    <v-autocomplete
                      dense
                      solo
                      hide-details
                      flat
                      outlined
                      :items="
                        hasValue(po.currency)
                          ? [
                              { text: '%', value: '%' },
                              { text: $store.getters['code/getCurrencyCode'](po.currency), value: '*' },
                            ]
                          : []
                      "
                      no-data-text="select the currency first"
                      v-model="po.changeAmountUom">
                      <template v-slot:label>
                        <span>SELECT</span>
                      </template>
                      <template v-slot:append>
                        <v-icon> mdi-chevron-down </v-icon>
                      </template>
                    </v-autocomplete>
                  </div>
                </td>
              </tr>
              <tr>
                <td>Purchase Order Value{{ hasValue(po.currency) ? ` (${po.currency})` : "" }}</td>
                <td>{{ numberWithComma(fixedNumber(finalAmountText, $mathFix, true)) }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="foot">
        <div class="d-flex justify-space-between">
          <div class="consignee">
            <p class="sub-title">Consignee</p>
            <span
              v-if="!editMode && !hasValue(po.consignee)"
              v-html="noDataFieldHTML"
              :class="{ required: !hasValue(po.consignee) }"></span>

            <v-autocomplete
              :class="{ required: !hasValue(po.consignee) }"
              v-if="editMode"
              dense
              solo
              hide-details
              flat
              outlined
              :items="companyList"
              item-text="name"
              item-value="cmpyUUID"
              v-model="po.consignee"
              @change="changeConsignee(po.consignee)">
              <template v-slot:label>
                <span class="font-error">COMPANY SEARCH</span>
              </template>
              <template v-slot:append>
                <v-icon> mdi-chevron-down </v-icon>
              </template>
            </v-autocomplete>

            <div class="foot-content" v-if="hasValue(po.consigneeCompany)">
              <p class="sub-title" v-if="po.consigneeCompany.name === 'PT. DAEHAN GLOBAL SUKABUMI'">PT. DAEHAN GLOBAL</p>
              <p class="sub-title" v-else-if="po.consigneeCompany.name === 'PT. DAEHAN GLOBAL BREBES(INTERIM ONLY)'">PT. DAEHAN GLOBAL</p>
              <p class="sub-title" v-else>{{po.consigneeCompany.name}}</p>
              {{ po.consigneeCompany.address }} <br />
              {{
                hasValue(po.consigneeCompany.cityCode)
                  ? `${po.consigneeCompany.cityCode}, ${po.consigneeCompany.countryCode}`
                  : `${po.consigneeCompany.countryCode}`
              }}

              <p class="contact">
                {{
                  `Tel : ${po.consigneeCompany.contactCallCountry + " " + po.consigneeCompany.contactCall} / Mail : ${
                    po.consigneeCompany.contactMail
                  }`
                }}
              </p>
            </div>
          </div>
          <div class="ship-to">
            <p class="sub-title">Ship To</p>
            <span
              v-if="!editMode && !hasValue(po.shipTo)"
              v-html="noDataFieldHTML"
              :class="{ required: !hasValue(po.shipTo) }"></span>

            <v-autocomplete
              :class="{ required: !hasValue(po.shipTo) }"
              v-if="editMode"
              dense
              solo
              hide-details
              flat
              outlined
              :items="companyList"
              item-text="name"
              item-value="cmpyUUID"
              v-model="po.shipTo"
              @change="changeShipTo(po.shipTo)">
              <template v-slot:label>
                <span class="font-error">COMPANY SEARCH</span>
              </template>
              <template v-slot:append>
                <v-icon> mdi-chevron-down </v-icon>
              </template>
            </v-autocomplete>

            <div class="foot-content" v-if="hasValue(po.shipToCompany)">
              <p class="sub-title" v-if="po.shipToCompany.name === 'PT. DAEHAN GLOBAL SUKABUMI'">PT. DAEHAN GLOBAL</p>
              <p class="sub-title" v-else-if="po.shipToCompany.name === 'PT. DAEHAN GLOBAL BREBES(INTERIM ONLY)'">PT. DAEHAN GLOBAL</p>
              <p class="sub-title" v-else>{{po.shipToCompany.name}}</p>
              {{ po.shipToCompany.address }} <br />
              {{
                hasValue(po.shipToCompany.cityCode)
                  ? `${po.shipToCompany.cityCode}, ${po.shipToCompany.countryCode}`
                  : `${po.shipToCompany.countryCode}`
              }}
              <p class="contact">
                {{
                  `Tel : ${po.shipToCompany.contactCallCountry + " " + po.shipToCompany.contactCall} / Mail : ${
                    po.shipToCompany.contactMail
                  }`
                }}
              </p>
            </div>
          </div>
        </div>
        <div class="d-flex justify-space-between">
          <div class="prepared-by">
            <p class="sub-title">Prepared By</p>
            <div class="foot-content">
              <p class="sub-title">
                {{ `${po.rmPoRegUser.name} (${po.rmPoRegUser.email}) / ${po.rmPoRegUser.mobilePhone}` }}
              </p>
              <p class="contact">
                {{
                  `Tel : ${po.userCompany.contactCallCountry + " " + po.userCompany.contactCall} / 
                  Mail : ${po.userCompany.contactMail}`
                }}
              </p>
            </div>
          </div>
          <div class="authorized-signature align-self-end">
            <p>Authorized Signature</p>
          </div>
        </div>
      </div>
    </div>

    <load-mcl
      :show.sync="loadMCLShow"
      :makePO="false"
      :selectedArea="{ ...loadMclArea }"
      :selectedSpplrUUID="po.spplrUUID"
      :createMode="loadMclCreateMode"
      :bomList="po.rmPoBomSelectDtoList"
      @load="mclLoaded" />

    <set-supplier-item
      :show.sync="setSupplierItemShow"
      :mtrlCostList="[]"
      :spplrName="po.spplrName"
      :createMode="createMode"
      :parentIsPO="true"
      @save="addedMclItem" />

    <save-pdf-option :show.sync="savePdfOptionShow" @exportPDF="exportPDF"></save-pdf-option>

    <confirm :show.sync="discardConfirmShow" :confirmButton="{ text: 'OK' }" @confirm="discard">
      Are you trying to discard this PO? <br />The deleted PO shall not be restored.
    </confirm>
    <confirm :show.sync="publishConfirmShow" :confirmButton="{ text: 'OK' }" @confirm="publish">
      You want to publish this PO?<br />This PO will be delivered to Supplier.
    </confirm>
    <confirm :show.sync="retrieveConfirmShow" :confirmButton="{ text: 'OK' }" @confirm="retrieve">
      You want to retrieve this PO?<br />The supplier can no longer check this PO.
    </confirm>
    <confirm :show.sync="rePublishConfirmShow" :confirmButton="{ text: 'OK' }" @confirm="rePublish">
      You want to publish this PO?<br />This PO will be delivered to Supplier.
    </confirm>
    <confirm :show.sync="deleteItemConfirmShow" :confirmButton="{ text: 'OK' }" @confirm="deleteMclItem">
      You want to delete Material? <br />Input Data cannot be recovered.
    </confirm>
    <confirm :show.sync="deleteAreaConfirmShow" :confirmButton="{ text: 'OK' }" @confirm="deleteArea">
      This insertion removes. <br />
      Good to go?
    </confirm>

    <div v-show="false">
      <po-detail-pdf ref="pdf" :po="po" @changeLoading="changeLoading"></po-detail-pdf>
    </div>

    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import LoadMcl from "@/components/company/po/LoadMcl.vue";
import SetSupplierItem from "@/components/company/bom/cbd/SetSupplierItem.vue";
import InputDate from "@/components/common/inputs/InputDate.vue";
import codeHandler from "@/utils/codeHandler";
import poDetailPdf from "@/components/company/pdf/poDetailPdf.vue";
import Confirm from "@/components/common/cards/Confirm.vue";
import { numberWithComma, hasValue, _deepCopy, isNumber, fixedNumber, calc } from "@/utils/util";
import SavePdfOption from "./SavePdfOption.vue";
import messageHandler from "@/utils/messageHandler";
import PoMixin from "@/mixins/PoMixin";

export default {
  components: { LoadMcl, SetSupplierItem, InputDate, poDetailPdf, Confirm, SavePdfOption },
  mixins: [PoMixin],
  data() {
    return {
      canPublish: false,
      po: {
        userCompany: {},
        supplier: {},
        rmPoBomSelectDtoList: [],
        rmPoRegUser: {},
      },
      backupPO: {},
      companyList: [],
      createMode: false,
      loading: false,
      saveLoading: false,
      editMode: false,
      loadMCLShow: false,
      loadMclArea: {},
      loadMclCreateMode: false,
      setItemTarget: {},
      setSupplierItemShow: false,
      exMillDateShow: false,
      shipDateDateShow: false,
      inHouseDateShow: false,
      savePdfOptionShow: false,
      discardConfirmShow: false,
      publishConfirmShow: false,
      retrieveConfirmShow: false,
      rePublishConfirmShow: false,
      deleteItemConfirmShow: false,
      deleteItemConfirmTarget: null,
      deleteAreaConfirmShow: false,
      deleteAreaConfirmTarget: null,
    };
  },
  methods: {
    getPoInfo() {
      const poUUID = this.$store.state.currentRoute.params.poUUID;

      const url = this.isReceived ? "/rmpo/received" : "rmpo";
      const data = this.isReceived
        ? { cmpyUUID: this.$store.getters.getCmpyUUID, rcvPoUUID: poUUID }
        : { cmpyUUID: this.$store.getters.getCmpyUUID, rmpoUUID: poUUID };

      this.loading = true;
      this.$http
        .get(url, { data })
        .then(res => {
          if (res.data.status === 200) {
            if (!hasValue(res.data.data)) {
              this.$toast.error("It's an abnormal approach.");
              this.$router.push({ name: this.isReceived ? "receivedRMPO" : "issuedRMPO" });
            }

            const { rmPoBomSelectDtoList } = res.data.data;

            if (Array.isArray(rmPoBomSelectDtoList) && rmPoBomSelectDtoList.length > 0) {
              for (let bomIndex = 0; bomIndex < rmPoBomSelectDtoList.length; bomIndex++) {
                /**
                 * ? Area
                 * * bom.mclUUID = undefined일 경우 사용자 정의 Area임.
                 */
                const bom = rmPoBomSelectDtoList[bomIndex];
                const { rmPoMclSelectDtoList } = bom;
                bom.mclUUID = this.isReceived ? bom.bomUUID : rmPoMclSelectDtoList[0]?.mclUUID;
                bom.mclList = [];

                if (Array.isArray(rmPoMclSelectDtoList) && rmPoMclSelectDtoList.length > 0) {
                  for (let mclIndex = 0; mclIndex < rmPoMclSelectDtoList.length; mclIndex++) {
                    /**
                     * ? MCL
                     * * sub-total 생성 필요 : mtrlUUID별로 Grouping, rmpo_mcl_qty_id로 Indexing
                     * *                      => bom.mclList =  [ {mtrlUUID: "", items: []}, {mtrlUUID: "", items: []}, ... ];
                     */
                    const mcl = rmPoMclSelectDtoList[mclIndex];
                    mcl.expandShow = false;
                    let mclListIdx = bom.mclList.findIndex(i => i.mtrlUUID === mcl.mtrlUUID);
                    if (mclListIdx === -1) {
                      bom.mclList.push({ mtrlUUID: mcl.mtrlUUID, items: [] });
                      mclListIdx = bom.mclList.length - 1;
                    }
                    bom.mclList[mclListIdx].items.push(mcl);
                  }
                }
              }
            }

            this.po = res.data.data;
            this.canPublish = this.checkPoInfo(true) && this.checkBomList(true);
          } else codeHandler(res.data);
        })
        .finally(() => (this.loading = false));
    },
    changeEditMode(editMode, notChanged) {
      if (editMode) {
        //수정모드로 진입
        this.backupPO = _deepCopy(this.po);

        //수정 모드로 진입 시, useDetailData가 Y일 경우 expandShow true처리
        if (Array.isArray(this.po.rmPoBomSelectDtoList) && this.po.rmPoBomSelectDtoList.length > 0) {
          for (let bomIndex = 0; bomIndex < this.po.rmPoBomSelectDtoList.length; bomIndex++) {
            const bom = this.po.rmPoBomSelectDtoList[bomIndex];
            const mclList = bom.mclList;
            if (Array.isArray(mclList) && mclList.length > 0) {
              for (let listIndex = 0; listIndex < mclList.length; listIndex++) {
                const items = mclList[listIndex].items;
                if (Array.isArray(items) && items.length > 0) {
                  for (let itemIndex = 0; itemIndex < items.length; itemIndex++) {
                    const item = items[itemIndex];
                    item.expandShow = item.isQtyDtl === "Y" ? true : false;
                  }
                }
              }
            }
          }
        }
      } else {
        // 읽기모드로 진입
        if (this.createMode && !notChanged) {
          //생성중이며 취소버튼 눌렀을떄
          this.$router.go(-1); //뒤로가기
        } else {
          //수정중이며 취소버튼 눌렀을때
          if (!notChanged) {
            this.po = _deepCopy(this.backupPO);
          }
          //수정&생성중이고 저장했을 때
          else this.getPoInfo();
        }
      }

      this.createMode = false;
      this.editMode = editMode;
    },
    changePoQty(item) {
      item.amount = calc((item.poQty || 0) * (item.unitPrice || 0));
    },
    changeQtyDtl(qty, item, changedUnitPrice) {
      if (changedUnitPrice) {
        const sumAmount = item.rmPoMclQtyDtlSelectDtoList
          .map(i => i.amount)
          .reduce((partialSum, a) => partialSum + (a || 0), 0);
        const sumPoQty = item.rmPoMclQtyDtlSelectDtoList
          .map(i => i.poQty)
          .reduce((partialSum, a) => partialSum + (a || 0), 0);
        item.unitPrice = calc(sumAmount / sumPoQty);
      }

      qty.amount = calc((qty.poQty || 0) * (qty.unitPrice || 0));
      item.poQty = item.rmPoMclQtyDtlSelectDtoList
        .map(i => i.poQty)
        .reduce((partialSum, a) => partialSum + (a || 0), 0);
      item.amount = calc((item.unitPrice || 0) * (item.poQty || 0));
    },
    discardClicked() {
      this.discardConfirmShow = true;
    },
    discard() {
      this.loading = true;
      this.$http
        .patch("/rmpo", { cmpyUUID: this.$store.getters.getCmpyUUID, rmpoUUID: this.po.rmpoUUID })
        .then(res => {
          if (res.data.status === 200) {
            this.$toast.success("Discarded successfully.");
            this.$router.push({ name: "issuedRMPO" });
          } else codeHandler(res.data);
        })
        .finally(() => (this.loading = false));
    },
    publishClicked() {
      if (!this.checkPoInfo(true) || !this.checkBomList(true)) return;
      this.publishConfirmShow = true;
    },
    publish() {
      if (!this.checkPoInfo(true) || !this.checkBomList(true)) return;
      this.loading = true;
      this.$http
        .put("/rmpo/status", {
          cmpyUUID: this.$store.getters.getCmpyUUID,
          rmpoUUID: this.po.rmpoUUID,
          status: "PUBLISH",
        })
        .then(res => {
          if (res.data.status === 200) {
            this.getPoInfo();
          } else codeHandler(res.data);
        })
        .finally(() => (this.loading = false));
    },
    retrieveClicked() {
      this.retrieveConfirmShow = true;
    },
    retrieve() {
      this.loading = true;
      this.$http
        .put("/rmpo/status", {
          cmpyUUID: this.$store.getters.getCmpyUUID,
          rmpoUUID: this.po.rmpoUUID,
          status: "RETRIEVE",
        })
        .then(res => {
          if (res.data.status === 200) {
            this.getPoInfo();
          } else codeHandler(res.data);
        })
        .finally(() => (this.loading = false));
    },
    rePublishClicked() {
      if (!this.checkPoInfo(true) || !this.checkBomList(true)) return;
      this.rePublishConfirmShow = true;
    },
    rePublish() {
      if (!this.checkPoInfo(true) || !this.checkBomList(true)) return;

      this.loading = true;
      this.$http
        .put("/rmpo/status", {
          cmpyUUID: this.$store.getters.getCmpyUUID,
          rmpoUUID: this.po.rmpoUUID,
          status: "RE-PUBLISH",
        })
        .then(res => {
          if (res.data.status === 200) {
            this.getPoInfo();
          } else codeHandler(res.data);
        })
        .finally(() => (this.loading = false));
    },
    save() {
      if (!this.checkBomList()) return;
      // if (!this.checkPoInfo() || !this.checkBomList()) return;

      const cmpyUUID = this.$store.getters.getCmpyUUID;

      //#region rmPoInsert
      const rmPoInsertDto = {
        rmpoUUID: this.po.rmpoUUID,
        spplrUUID: this.po.spplrUUID,
        spplrName: this.po.spplrName,
        cntryOrg: this.po.cntryOrg,
        cntryDst: this.po.cntryDst,
        portLoad: this.po.portLoad,
        portDis: this.po.portDis,
        consignee: this.po.consignee,
        shipTo: this.po.shipTo,
        exMill: this.po.exMill,
        shipDate: this.po.shipDate,
        inHouse: this.po.inHouse,
        termDelivery: this.po.termDelivery,
        termPayment: this.po.termPayment,
        termPeriod: this.po.termPeriod,
        termDay: this.po.termDay,
        shipMode: this.po.shipMode,
        shipToleranceLower: this.po.shipToleranceLower,
        shipToleranceHigher: this.po.shipToleranceHigher,
        currency: this.po.currency,
        partialShipment: this.po.partialShipment,
        changeAmountInfo: this.po.changeAmountInfo,
        changeAmount: this.po.changeAmount,
        changeAmountUom: this.po.changeAmountUom,
        amount: isNumber(this.finalAmountText) ? this.finalAmountText : 0,
        amountUom: this.po.currency,
      };

      rmPoInsertDto.designNo = this.po.rmPoBomSelectDtoList
        ?.map(bom => bom.designNo)
        .reduce((preVal, newVal) => {
          if (hasValue(newVal)) {
            return (preVal += (hasValue(preVal) ? "|$|" : "") + newVal);
          } else return preVal;
        }, "");
      rmPoInsertDto.styleNo = this.po.rmPoBomSelectDtoList
        ?.map(bom => bom.styleNo)
        .reduce((preVal, newVal) => {
          if (hasValue(newVal)) {
            return (preVal += (hasValue(preVal) ? "|$|" : "") + newVal);
          } else return preVal;
        }, "");
      rmPoInsertDto.offerBrand = this.po.rmPoBomSelectDtoList
        ?.map(bom => bom.offerBrand)
        .reduce((preVal, newVal) => {
          if (hasValue(newVal)) {
            return (preVal += (hasValue(preVal) ? "|$|" : "") + newVal);
          } else return preVal;
        }, "");

      let items = 0;
      if (Array.isArray(this.po.rmPoBomSelectDtoList) && this.po.rmPoBomSelectDtoList.length > 0) {
        for (let bomIndex = 0; bomIndex < this.po.rmPoBomSelectDtoList.length; bomIndex++) {
          const bom = this.po.rmPoBomSelectDtoList[bomIndex];
          const mclList = bom.mclList;
          if (Array.isArray(mclList) && mclList.length > 0) {
            for (let listIndex = 0; listIndex < mclList.length; listIndex++)
              items += mclList[listIndex].items.length || 0;
          }
        }
      }
      rmPoInsertDto.items = items;
      //#endregion

      //#region rmPoBom Insert
      const rmPoBomInsertDtoList = [];
      if (Array.isArray(this.po.rmPoBomSelectDtoList) && this.po.rmPoBomSelectDtoList.length > 0) {
        for (let bomIndex = 0; bomIndex < this.po.rmPoBomSelectDtoList.length; bomIndex++) {
          const bom = this.po.rmPoBomSelectDtoList[bomIndex];
          bom.areaOrder = bomIndex;
          const mclList = bom.mclList;
          bom.rmPoMclInsertDtoList = [];
          if (Array.isArray(mclList) && mclList.length > 0) {
            for (let listIndex = 0; listIndex < mclList.length; listIndex++) {
              const items = mclList[listIndex].items;
              if (Array.isArray(items) && items.length > 0) {
                for (let itemIndex = 0; itemIndex < items.length; itemIndex++) {
                  const item = items[itemIndex];
                  item.rmPoMclQtyDtlInsertDtoList = item.rmPoMclQtyDtlSelectDtoList;
                  item.mclOrder = bom.rmPoMclInsertDtoList.length;
                  bom.rmPoMclInsertDtoList.push(item);
                }
              }
            }
          }
          rmPoBomInsertDtoList.push(bom);
        }
      }
      //#endregion

      this.saveLoading = true;
      this.$http
        .post("/rmpo", { cmpyUUID, rmPoInsertDto, rmPoBomInsertDtoList })
        .then(res => {
          if (res.data.status === 200) {
            if (!this.createMode) this.changeEditMode(false, true);
            if (this.createMode) {
              if (res.data.data.rmpoUUID)
                this.$router.push({ name: "issuedRMPODetail", params: { poUUID: res.data.data.rmpoUUID } });
              else this.$router.go(-1);
            }
          } else codeHandler(res.data);
        })
        .finally(() => (this.saveLoading = false));
    },
    checkPoInfo(publishCheck) {
      if (
        ![
          this.po.cntryDst,
          this.po.cntryOrg,
          this.po.portLoad,
          this.po.portDis,
          this.po.exMill,
          this.po.shipDate,
          this.po.inHouse,
          this.po.termDelivery,
          this.po.termPayment,
          this.po.termPeriod,
          this.po.termDay,
          this.po.shipMode,
          this.po.currency,
          this.po.shipToleranceHigher,
          this.po.shipToleranceLower,
          this.po.partialShipment,
          this.po.consignee,
          this.po.shipTo,
        ].every(hasValue)
      ) {
        if (!publishCheck) this.$toast.warning("Please enter all the required items");
        return false;
      }

      if (![this.po.shipToleranceHigher, this.po.shipToleranceLower].every(isNumber)) {
        if (!publishCheck) this.$toast.warning("Please only be entered with numbers");
        return false;
      }
      return true;
    },
    checkBomList(publishCheck) {
      if (!Array.isArray(this.po.rmPoBomSelectDtoList) || this.po.rmPoBomSelectDtoList.length < 1) {
        this.$toast.warning("There must be at least one Area");
        return false;
      }

      for (let bomIndex = 0; bomIndex < this.po.rmPoBomSelectDtoList.length; bomIndex++) {
        const bom = this.po.rmPoBomSelectDtoList[bomIndex];

        if (publishCheck) {
          if (![bom.areaName].every(hasValue)) {
            return false;
          }
        }

        if (!Array.isArray(bom.mclList) || bom.mclList.length < 1) {
          if (!publishCheck) this.$toast.warning("Area must have at least one item");
          return false;
        }

        if (publishCheck) {
          for (let mclIndex = 0; mclIndex < bom.mclList.length; mclIndex++) {
            const mclList = bom.mclList[mclIndex];
            const items = mclList.items;

            if (Array.isArray(items) && items.length > 0) {
              for (let itemIndex = 0; itemIndex < items.length; itemIndex++) {
                const item = items[itemIndex];
                if (![item.color, item.unitPrice, item.fwCwUom, item.poQty].every(hasValue)) {
                  if (!publishCheck) this.$toast.warning("Please enter all required fields within the item");
                  return false;
                }
                if (![item.unitPrice, item.poQty].every(isNumber)) {
                  if (!publishCheck) this.$toast.warning("Please only be entered with numbers");
                  return false;
                }

                if (Array.isArray(item.rmPoMclQtyDtlSelectDtoList) && item.rmPoMclQtyDtlSelectDtoList.length > 0) {
                  for (let qtyIndex = 0; qtyIndex < item.rmPoMclQtyDtlSelectDtoList.length; qtyIndex++) {
                    const qty = item.rmPoMclQtyDtlSelectDtoList[qtyIndex];

                    if (![qty.unitPrice, qty.poQty].every(hasValue)) {
                      if (!publishCheck) this.$toast.warning("Please enter all required fields within the item");
                      return false;
                    }
                    if (![qty.unitPrice, qty.poQty].every(isNumber)) {
                      if (!publishCheck) this.$toast.warning("Please only be entered with numbers");
                      return false;
                    }
                  }
                }
              }
            }
          }
        }
      }
      return true;
    },
    loadMCL(bom) {
      if (!hasValue(bom.bomUUID) && Array.isArray(bom.mclList) && bom.mclList.length === 0) {
        this.loadMclCreateMode = true;
        this.loadMclArea = bom;
      } else {
        this.loadMclArea = bom;
      }
      this.loadMCLShow = true;
    },
    mclLoaded(list) {
      const mclList = this.loadMclArea.mclList;

      if (!hasValue(this.po.spplrUUID)) {
        //po 최초로 loadMcl한 경우
        const spplrUUID = list.rmPoMclSelectDtoList[0].spplrUUID;
        const spplr = this.companyList.find(c => c.cmpyUUID === spplrUUID);
        this.po.spplrUUID = spplr.cmpyUUID;
        this.po.spplrName = spplr.name;
        this.po.supplier = spplr;
      }

      if (this.loadMclCreateMode) {
        //area 최초로 loadMcl한 경우
        Object.assign(this.loadMclArea, list);
        this.loadMclArea.mclUUID = list.rmPoMclSelectDtoList[0].mclUUID;
        list = list.rmPoMclSelectDtoList;
      }

      this.loadMclCreateMode = false;

      for (let mclIndex = 0; mclIndex < list.length; mclIndex++) {
        const mcl = list[mclIndex];
        mcl.expandShow = false;
        mcl.tempRegDt = Date.now();
        let mclListIdx = mclList.findIndex(i => i.mtrlUUID === mcl.mtrlUUID);
        if (mclListIdx === -1) {
          mclList.push({ mtrlUUID: mcl.mtrlUUID, items: [] });
          mclListIdx = mclList.length - 1;
        }
        this.changePoQty(mcl);
        mclList[mclListIdx].items.push(mcl);
      }
    },
    deleteMclItemClicked(bom, list, item) {
      this.deleteItemConfirmShow = true;
      this.deleteItemConfirmTarget = { bom, list, item };
    },
    deleteMclItem() {
      if (!hasValue(this.deleteItemConfirmTarget)) return messageHandler("error");
      const { bom, list, item } = this.deleteItemConfirmTarget;

      //bom : area
      //list: mclList in list in items
      //item: mclList in list in items in item
      let areaTotalItemCnt = 0;
      for (let mclListIndex = 0; mclListIndex < bom.mclList.length; mclListIndex++)
        areaTotalItemCnt += bom.mclList[mclListIndex].items.length || 0;

      if (areaTotalItemCnt === 1) {
        //1개밖에 남지않았음. area 삭제
        this.po.rmPoBomSelectDtoList.splice(
          this.po.rmPoBomSelectDtoList.findIndex(i => i === bom),
          1,
        );
      } else {
        list.splice(
          list.findIndex(i => i === item),
          1,
        );
      }
    },
    addMclItem(list) {
      this.setSupplierItemShow = true;
      this.setItemTarget = list;
    },
    addedMclItem(items) {
      if (!hasValue(this.po.spplrUUID)) {
        //po 최초로 loadMcl한 경우
        const spplrUUID = items[0].spplrUUID;
        const spplr = this.companyList.find(c => c.cmpyUUID === spplrUUID);
        this.po.spplrUUID = spplr.cmpyUUID;
        this.po.spplrName = spplr.name;
        this.po.supplier = spplr;
      }

      const mclList = this.setItemTarget.mclList;
      for (let mclIndex = 0; mclIndex < items.length; mclIndex++) {
        const mcl = _deepCopy(items[mclIndex]);
        mcl.expandShow = false;
        mcl.poQty = 0;
        mcl.amount = 0;
        mcl.mtrlCompRate = mcl.itemDetail;
        mcl.tempRegDt = Date.now();
        mcl.rmPoMclQtyDtlSelectDtoList = [];
        let mclListIdx = mclList.findIndex(i => i.mtrlUUID === mcl.mtrlUUID);
        if (mclListIdx === -1) {
          mclList.push({ mtrlUUID: mcl.mtrlUUID, items: [] });
          mclListIdx = mclList.length - 1;
        }
        mclList[mclListIdx].items.push(mcl);
      }
    },
    addArea() {
      this.po.rmPoBomSelectDtoList.push({
        areaName: "",
        bomUUID: undefined,
        mclList: [],
      });
    },
    getNO(mclList, mclIndex, mclItemIndex) {
      let sum = 0;
      for (let listIndex = 0; listIndex < mclIndex; listIndex++) {
        sum += mclList[listIndex].items.length;
      }

      return sum + mclItemIndex + 1;
    },
    getCompanyList() {
      this.$http.get("/company/list").then(res => {
        if (res.data.status === 200) {
          this.companyList = res.data.data;
        } else codeHandler(res.data);
      });
    },
    changeConsignee(consignee) {
      if (hasValue(consignee)) {
        this.po.consigneeCompany = this.companyList.find(c => c.cmpyUUID === consignee);
      }
    },
    changeShipTo(shipTo) {
      if (hasValue(shipTo)) {
        this.po.shipToCompany = this.companyList.find(c => c.cmpyUUID === shipTo);
      }
    },
    changeIsQtyDtl(item) {
      if (item.isQtyDtl === "Y") {
        this.loading = true;
        this.$http
          .get("/rmpo/mcl-qty-dtl/calc", {
            data: {
              cmpyUUID: this.$store.getters.getCmpyUUID,
              mclPlanId: item.mclPlanId,
              mclUUID: item.mclUUID,
              poQty: item.poQty,
              rmpoMclId: item.rmpoMclId,
            },
          })
          .then(res => {
            if (res.data.status === 200) {
              const newQtyDtl = res.data.data;
              for (let qtyDtlIndex = 0; qtyDtlIndex < item.rmPoMclQtyDtlSelectDtoList.length; qtyDtlIndex++) {
                if (hasValue(newQtyDtl[qtyDtlIndex]) && hasValue(item.rmPoMclQtyDtlSelectDtoList[qtyDtlIndex]))
                  newQtyDtl[qtyDtlIndex].remark = item.rmPoMclQtyDtlSelectDtoList[qtyDtlIndex].remark;
              }
              item.unitPrice = newQtyDtl[0].unitPrice || 0;
              this.changePoQty(item);
              item.rmPoMclQtyDtlSelectDtoList = res.data.data;
            } else codeHandler(res.data);
          })
          .finally(() => (this.loading = false));
      }
    },
    checkPDFOption() {
      this.savePdfOptionShow = true;
    },
    exportPDF(showDetail) {
      this.$refs.pdf.html2PDF(showDetail);
    },
    changeLoading(val) {
      this.loading = val;
    },
    copyMclItem(bom, list, mclItem) {
      //bom : area
      //list: mclList in list in items
      //item: mclList in list in items in item
      list.push();

      if (hasValue(list) && hasValue(mclItem)) {
        const index = list.findIndex(i => i === mclItem);
        if (index !== -1) {
          const newPlan = _deepCopy(mclItem);
          newPlan.rmpoMclId = undefined;
          list.splice(index + 1, 0, newPlan);
        }
      }
    },
    deleteAreaClicked(bom) {
      this.deleteAreaConfirmTarget = bom;
      this.deleteAreaConfirmShow = true;
    },
    deleteArea() {
      if (this.deleteAreaConfirmTarget) {
        this.po.rmPoBomSelectDtoList.splice(
          this.po.rmPoBomSelectDtoList.findIndex(i => i === this.deleteAreaConfirmTarget),
          1,
        );
      }
    },
    fixedNumber,
    numberWithComma,
    hasValue,
  },
  computed: {
    isReceived() {
      return this.$store.state.currentRoute.name === "receivedRMPODetail";
    },
    workHistoryText() {
      if (Array.isArray(this.po.rmPoWorkHistSelectDtoList) && this.po.rmPoWorkHistSelectDtoList.length > 0) {
        return this.po.rmPoWorkHistSelectDtoList
          .slice(
            this.po.rmPoWorkHistSelectDtoList.length - 10 < 0 ? 0 : this.po.rmPoWorkHistSelectDtoList.length - 10,
            this.po.rmPoWorkHistSelectDtoList.length,
          )
          .reverse()
          .map(wh => `${wh.workType?.toUpperCase()} ${this.$m(wh.sysRegDt).format("yyyy-MM-DD HH:mm")}`)
          .join("<br/>");
      }
      return "";
    },
  },
  created() {
    this.$watch(
      () => this.$route.params,
      () => {
        this.editMode = false;
        this.createMode = false;
        this.getPoInfo();
      },
    );

    if (this.$store.state.currentRoute.params.poUUID === "create") {
      this.createMode = true;
      this.editMode = true;
      this.po.rmPoRegUser = this.$store.state.user;
      const company = this.$store.state.user.companyList.find(c => c.cmpyUUID === this.$store.getters.getCmpyUUID);
      company.name = company.companyName;
      this.po.userCompany = company;
      this.addArea();
    } else {
      this.getPoInfo();
    }
    this.getCompanyList();
  },
};
</script>

<style lang="scss" scoped></style>
