<template>
  <v-dialog
    v-model="dialogShow"
    min-width="300px"
    max-width="500px"
    content-class="company save-pdf-option customEditModal">
    <v-card class="pa-5">
      <h1>SAVE AS PDF DOCUMENT</h1>
      <div class="mt-10 content">
        <v-radio-group v-model="selectedOption">
          <v-radio color="secondary" label="SUMMARY" value="S" />
          <v-radio color="secondary" label="DETAIL DEPENDENCY" value="D" />
        </v-radio-group>
      </div>
      <div class="actions d-flex justify-center">
        <v-btn :elevation="0" color="secondary" @click="save"> DOWNLOAD </v-btn>
        <v-btn :elevation="0" color="secondary" @click="dialogShow = false"> CANCEL </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ["show"],
  data() {
    return {
      selectedOption: "S",
    };
  },
  methods: {
    save() {
      this.$emit("exportPDF", this.selectedOption === "D");
      this.dialogShow = false;
    },
  },
  computed: {
    dialogShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
  },
};
</script>

<style></style>
